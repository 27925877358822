import React, {useEffect, useContext, useState, useCallback} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from 'react-query';

import { UserContext, AccessTokenContext } from '../../context/context.js';
import { useAlert } from "../../context/alert-context.js";
import { useDispatch, useSelector } from 'react-redux';
import useDistanceRequests from '../../hooks/distance-request.js';
import usePatientRequests from '../../hooks/patient-requests.js';
import useScheduleRequests from '../../hooks/schedule-requests.js';
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import './calendar-extra.css';
import './calendar.css';
import styles from "./Calendar.module.css";

import { momentLocalizer, Calendar as BigCalendar } from 'react-big-calendar';
import SchedulePreferences from '../../Modal_Components/Schedule_Preferences.js';
import FloatingModal from '../../static/Modals/Floating_Modal.js';
import PatientScheduleList from '../Patient_Schedule_List/Patient_Schedule_List.js';
import ModalComponent from '../../static/Modals/Modal_Component.js';
import EventModal from '../../Modal_Components/Event_Modal.js';
import Loading from '../../pop-ups/loading.js';
import LoadingModal from '../../pop-ups/loading-modal.js';
import ScheduleTutorial from '../../Modal_Components/Schedule_Faqs.js';
import GroupingMap from '../Maps/Grouping_Map.js';
import GeneralMap from '../Maps/General_Map.js';

const DnDCalendar = withDragAndDrop(BigCalendar);
const localizer = momentLocalizer(moment);

function Calendar(props) {
  const user = useContext(UserContext);
  const accessToken = useContext(AccessTokenContext);
  
  const { showAlert } = useAlert();
  const { getPatients } = usePatientRequests();
  const { getTimeDistances } = useDistanceRequests();
  const { saveEventToSchedule, updateEvent, getUserSchedule, clearScheduleFromDay, deleteEvent } = useScheduleRequests();
  const dispatch = useDispatch();
  const { isGroupingOn, isMapExpanded } = useSelector((state) => state.patients);

  const [myEvents, setMyEvents] = useState([]);
  const [draggedClient, setDraggedClient] = useState();
  const [calViewChange, setCalViewChange] = useState(false);
  const [viewFocus, setViewFocus] = useState({
    edit: false,
    view: 'Patient'
  });
  const [testDay, setTestDay] = useState(undefined);
  const [therapistParameters, setTherapistParameters] = useState({
    workingDays: user.workingDays,
    sessionLength: user.sessionLength,
    bufferTime: user.bufferTime,
    startTime: user.startTime
  });
  const [viewStartDate, setViewStartDate] = useState(null);
  const [viewEndDate, setViewEndDate] = useState(null);
  const [isLoadingTest, setIsLoadingTest] = useState(false);
  const [stillFetching, setStillFetching] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [moveEventQueue, setMoveEventQueue] = useState([]);
  const [isProcessingMove, setIsProcessingMove] = useState(false);
  const [showEventModal, setShowEventModal] = useState(false);
  const [showSchedulePrefModal, setShowSchedulePrefModal] = useState(false);
  const [showTutorialModal, setShowTutorialModal] = useState(false);
  const [eventModalProps, setEventModalProps] = useState({});
  const [isDayViewActive, setIsDayViewActive] = useState(false);
  const EventStatus = {
    NOT_TESTED: "not_tested", 
    CONFIRMED: "confirmed", 
    REJECTED: "rejected", 
    BAD: "bad" 
  };

  const { data: homes, homeStatus } = useQuery(["homes"], 
    () => getPatients(user.authId, accessToken)
  );

  const { data: dbSchedule, dbScheduleStatus, refetch } = useQuery(["schedule"], 
    () => getUserSchedule(user.authId, accessToken), {
      onSuccess: (data) => {
        if(data.status === undefined) {
          fillInCalendar(data); 
          setHasError(false);
        } else {
          showAlert(
            "We're unable to load your current schedule. Please try refreshing your page. If this issue persists, kindly provide us with feedback. Thanks for your patience.", 
            "Oh, Snap", 'danger'
          );
          setHasError(true);
        }
        
      },
      onError: () => {
        showAlert(
          "We're unable to load your current schedule. Please try refreshing your page. If this issue persists, kindly provide us with feedback. Thanks for your patience.", 
          "Oh, Snap", 'danger'
        );
        setHasError(true);
      }
    }
  );  

  const handleNavigate = date => {
    let newStart, newEnd;

    const newDate = new Date(date).getTime();
    const viewStart = new Date(viewStartDate).getTime();
    const viewEnd = new Date(viewEndDate).getTime();

    if(newDate > viewStart && newDate > viewEnd) {
      newStart = localizer.add(viewStartDate, 7, 'days');
      newEnd = localizer.add(viewEndDate, 7, 'days');
    } else {
      newStart = localizer.add(viewStartDate, -7, 'days');
      newEnd = localizer.add(viewEndDate, -7, 'days');
    }

    setViewStartDate(newStart);
    setViewEndDate(newEnd);
  }

  useEffect(() => {
    const currentDay = new Date(moment().toLocaleString('en-US', { weekday: 'short' })).getDay();
    const startDate = localizer.add(moment(), -currentDay, 'days');
    const endDate = localizer.add(moment(), (6-currentDay), 'days'); 

    setViewStartDate(startDate);
    setViewEndDate(endDate);
  }, []);

  useEffect(() => {
    const timeSlots = document.querySelectorAll('.rbc-time-slot');
    const timeSlotsGroups = document.querySelectorAll('.rbc-timeslot-group');

    for (let i = 0; i < timeSlots.length; i++) {
      if (i % 6 !== 0) {
        const label = timeSlots[i].querySelector('.rbc-label');
        if (label) {
          label.style.display = 'none';
        }
      }
    }

    for (let i = 0; i < timeSlotsGroups.length; i++) {
      if (i % 6 === 0) {
        const slotGroup = timeSlotsGroups[i];
        slotGroup.style.border = '1px solid #a5a4a4';
      }
    }
  }, [calViewChange]);
  
  useEffect(() => {
    handleRefetch();
  }, [props.isOpen]);
  
  const checkActiveView = () => {
    setTimeout(() => {
      const dayButton = Array.from(document.querySelectorAll('.rbc-toolbar button')).find(
        (button) => button.textContent === 'Day'
      );
  
      if (dayButton && dayButton.classList.contains('rbc-active')) {
        setIsDayViewActive(true);
      } else {
        setIsDayViewActive(false);
      }
    }, 0); // 0ms delay to allow for the DOM update
  };

  useEffect(() => {
    // Initial check if the "Day" view is active when the component mounts
    checkActiveView();

    // Add an event listener to detect when the toolbar buttons are clicked
    const toolbar = document.querySelector('.rbc-toolbar');
    toolbar?.addEventListener('click', checkActiveView);

    // Clean up the event listener when the component unmounts
    return () => {
      toolbar?.removeEventListener('click', checkActiveView);
    };
  }, []);
  
  const handleSchedulePreferences = () => setShowSchedulePrefModal(true);
 
  const handleTutorial = () => setShowTutorialModal(true);
  
  const handleRefetch = async () => {
    const { data, error, failureCount, isFetching } = await refetch();
    if (data.error) {
      console.log('this is working')
    }

    if (data) {
      setStillFetching(false);
    }
  }

  const changeView = () => {
    setCalViewChange(!calViewChange);
  }
  
  const eventPropGetter = (event) => {
    let className = '';
  
    if (event.isViableDest === EventStatus.REJECTED && event.isViableOrg === EventStatus.CONFIRMED) {
      className += 'orgVDestN ';
    }
  
    if (event.isViableDest === EventStatus.CONFIRMED && event.isViableOrg === EventStatus.REJECTED) {
      className += 'orgNDestV ';
    }
  
    if (event.isViableDest === EventStatus.REJECTED && event.isViableOrg === EventStatus.NOT_TESTED) {
      className += 'orgNullDestN ';
    }
  
    if (event.isViableDest === EventStatus.NOT_TESTED && event.isViableOrg === EventStatus.REJECTED) {
      className += 'orgNDestNull ';
    }
  
    if (event.isViableDest === EventStatus.NOT_TESTED && event.isViableOrg === EventStatus.CONFIRMED) {
      className += 'orgVDestNull ';
    }
  
    if (event.isViableDest === EventStatus.CONFIRMED && event.isViableOrg === EventStatus.NOT_TESTED) {
      className += 'orgNullDestV ';
    }
  
    if (event.isViableDest === EventStatus.REJECTED && event.isViableOrg === EventStatus.REJECTED) {
      className += 'bothNotViable ';
    }
  
    if (event.isViableDest === EventStatus.CONFIRMED && event.isViableOrg === EventStatus.CONFIRMED) {
      className += 'bothViable ';
    }

    if (event.isViableDest === EventStatus.REJECTED && event.isViableOrg === EventStatus.BAD) {
      className += 'orgBDestN ';
    }

    if (event.isViableDest === EventStatus.BAD && event.isViableOrg === EventStatus.REJECTED) {
      className += 'orgNDestB ';
    }

    if (event.isViableDest === EventStatus.CONFIRMED && event.isViableOrg === EventStatus.BAD) {
      className += 'orgBDestV ';
    }

    if (event.isViableDest === EventStatus.BAD && event.isViableOrg === EventStatus.CONFIRMED) {
      className += 'orgVDestB ';
    }

    if (event.isViableDest === EventStatus.BAD && event.isViableOrg === EventStatus.BAD) {
      className += 'orgBDestB ';
    }
  
    if (event.additional) {
      className += 'additional-day ';
    }
    
    className = className.trim();

    // setIsLoadingTest(false);
  
    return { className }
  }   

  const eventViability = async (viabilityData) => {
    if(viabilityData) {
      const response = await getUserSchedule(user.authId, accessToken);
      const retrieveId = (event) => {
        const matchedEvent = response.find((ev) => ev.eventId === event.eventId);
         matchedEvent._id;
        return matchedEvent._id;
      }

      if(response.status !== undefined) {
        showAlert(
          "We're experiencing difficulty retrieving this patient's data. Please refresh your page and try again. If the problem continues, kindly provide us with feedback so we can assist you further.", "Oh, Snap!", 'danger'
        );
      } else {
        for (let i = 0; i < viabilityData.length; i++) {
          const element = viabilityData[i];

          let origin = myEvents.find((event) => event.eventId === element.originId);
          origin.isViableOrg = element.isViable;
          const filteredOriginState = myEvents.filter((event) => event.eventId !== element.originId);
          if(!origin._id) {
            const idToUse = retrieveId(origin);
            origin._id = idToUse;
          }
          const responseO = await updateEvent(origin, accessToken);
          if(responseO.status === 200) {
            setMyEvents(() => {
              return [...filteredOriginState, {...origin}]
            });
          } else {
            showAlert(
              "We're experiencing difficulty verifying the details of your schedule. Please ensure that you have a stable internet connection and try refreshing the page. If the problem continues, kindly provide us with feedback so we can assist you further.", 
              "Trouble Processing Your Schedule", 
              'danger'
            );
            break;
          }
          let destination = myEvents.find((event) => event.eventId === element.destinationId);
          destination.isViableDest = element.isViable;
          const filteredDestinationState = myEvents.filter((event) => event.eventId !== element.destinationId);
          if(!destination._id) {
            const idToUse = retrieveId(destination);
            destination._id = idToUse;
          }
          const responseD = await updateEvent(destination, accessToken);
          if(responseD.status === 200) {
            setMyEvents(() => {
              return [...filteredDestinationState, {...destination}]
            });
          } else {
            showAlert(
              "We're experiencing difficulty verifying the details of your schedule. Please ensure that you have a stable internet connection and try refreshing the page. If the problem continues, kindly provide us with feedback so we can assist you further.", "Trouble Processing Your Schedule", 'danger'
            );
            break;
          }
        }
        setTimeout(() => setIsLoadingTest(false), 750); 
      }
    } 
  }

  const updateViability = async (scheduleOfDay, event) => {
    const movedIndex = scheduleOfDay.findIndex((ev) => ev.eventId === event.eventId);

    const origin = scheduleOfDay[movedIndex - 1] ?? undefined;
    const destination = scheduleOfDay[movedIndex + 1] ?? undefined;

    if(origin && origin.isViableOrg !== EventStatus.NOT_TESTED) {
      let originToChange = myEvents.find((ev) => ev.eventId === origin.eventId);
      originToChange.isViableOrg = EventStatus.NOT_TESTED;
      const filteredOriginState = myEvents.filter((ev) => ev.eventId !== originToChange.eventId);

      const response = await updateEvent(originToChange, accessToken);
      if(response.status === 200) {
        setMyEvents(() => {
          return [...filteredOriginState, {...originToChange}]
        });
      } else {
        showAlert(
          "We're experiencing difficulty verifying the details of your schedule. Please ensure that you have a stable internet connection and try refreshing the page. If the problem continues, kindly provide us with feedback so we can assist you further.", 
          "Trouble Processing Your Schedule", 
          'danger'
        );
      }
    }

    if(destination && destination.isViableDest !== EventStatus.NOT_TESTED) {
      let destinationToChange = myEvents.find((ev) => ev.eventId === destination.eventId);
      destinationToChange.isViableDest = EventStatus.NOT_TESTED;
      const filteredDestinationState = myEvents.filter((ev) => ev.eventId !== destinationToChange.eventId);

      const response = await updateEvent(destinationToChange, accessToken);
      if(response.status === 200) {
        setMyEvents(() => {
          return [...filteredDestinationState, {...destinationToChange}]
        });
      } else {
        showAlert(
          "We're experiencing difficulty verifying the details of your schedule. Please ensure that you have a stable internet connection and try refreshing the page. If the problem continues, kindly provide us with feedback so we can assist you further.", 
          "Trouble Processing Your Schedule", 
          'danger'
        );
      }
    }
  }

  const fillInCalendar = (dbSchedule) => {
    const schedule = dbSchedule.map((event) => {
      const startTime = new Date(event.start);
      const endTime = new Date(event.end);

      event.start = startTime;
      event.end = endTime;

      return event; 
    });

    
    setMyEvents([...schedule]);
  }

  const newEvent = useCallback(async (event) => {
    const response = await saveEventToSchedule(user.authId, event, accessToken);
    if(response.status === 201) {
      setMyEvents((prev) => {
        return [...prev, { ...event }]
      });
    } else {
      showAlert(`ERROR: ${response.message}`, 'Oh, Snap!', 'danger');
    }
    
  },[setMyEvents]);

  const onDropFromOutside = useCallback(
    ({ start, allDay: isAllDay }) => {

      const end = new Date(start.getTime() + therapistParameters.sessionLength * 60000);

      const {client, address, coordinates, additional} = draggedClient;

      const event = {
        eventId: uuidv4(),
        title: client,
        address: address,
        coordinates: coordinates,
        additional: additional,
        start,
        end,
        isAllDay,
        isViableOrg: EventStatus.NOT_TESTED,
        isViableDest: EventStatus.NOT_TESTED,
      }

      newEvent(event);
    },
    [newEvent, draggedClient]
  );

  const handleMoveEvent = async ({ event, start, end, allDay: isAllDay }) => {
    const viewStart = new Date(viewStartDate).getTime();
    const viewEnd = new Date(viewEndDate).getTime();
    const moveFromDay = event.start.toLocaleString('en-US', { weekday: 'long' });
    const moveToDay = start.toLocaleString('en-US', { weekday: 'long' });

    const scheduleOfMovedFrom = myEvents.filter((ev) => {
      const day = ev.start.toLocaleString('en-US', { weekday: 'long' });

      const start = new Date(ev.start).getTime();

      return day === moveFromDay && (start >= viewStart && start <= viewEnd);
    }).sort((a, b) => a.start - b.start);

    const proceedWithMove = () => {
       moveEvent({ event, start, end, allDay: isAllDay })
        .then(() => {
          updateViability(scheduleOfMovedFrom, event);

          const scheduleOfMovedTo = myEvents.filter((ev) => {
            const day = ev.start.toLocaleString('en-US', { weekday: 'long' });
      
            const start = new Date(ev.start).getTime();
      
            return day === moveToDay && (start >= viewStart && start <= viewEnd);
          }).sort((a, b) => a.start - b.start);

          updateViability(scheduleOfMovedTo, event);
        })
        .catch((error) => {
          console.error('Failed to move event:', error);
          setMyEvents(myEvents);
          showAlert(
            "We're experiencing difficulty verifying the move you're attempting to make. Please ensure that you have a stable internet connection and try refreshing the page. If the problem continues, kindly provide us with feedback so we can assist you further.",
            "Trouble Processing Your Schedule",
            'danger'
          );
        });
    }

    if(!event._id) {
      const response = await getUserSchedule(user.authId, accessToken);
      if(response.status === undefined) {
        const matchedEvent = response.find((ev) => ev.eventId === event.eventId);
        event._id = matchedEvent._id;

        proceedWithMove();
      } else {
        showAlert(
          "We're experiencing difficulty retrieving this patient's data. Please refresh your page and try again. If the problem continues, kindly provide us with feedback so we can assist you further.", 
          "Oh, Snap!", 
          'danger'
        );
      }
    } else {
      proceedWithMove();
    }
    
  }
  
  const processEventQueue = useCallback(async () => {
    if (isProcessingMove || moveEventQueue.length === 0) return;

    setIsProcessingMove(true);
    const { event, start, end, isAllDay, resolve, reject } = moveEventQueue[0];

    let timeoutId;

    try {
      let newEvent = event;
      newEvent.start = start;
      newEvent.end = end;
      newEvent.isAllDay = isAllDay;

      const throwLoadingModal = new Promise((_, rejectTimeout) => {
        timeoutId = setTimeout(() => {
          setIsLoadingTest(true);
          showAlert(
            'This operation is taking longer than expected. Please be patient.',
            'Slow Network Detected',
            'warning'
          );
        }, 750);
      });

      const updateEventPromise = updateEvent(newEvent, accessToken);

      const response = await Promise.race([updateEventPromise, throwLoadingModal]);
      clearTimeout(timeoutId);
      setIsLoadingTest(false);

      if (response.status === 200) {
        resolve();
      } else {
        showAlert(
          "We're experiencing difficulty verifying the move you're attempting to make. Please ensure that you have a stable internet connection and try refreshing the page. If the problem continues, kindly provide us with feedback so we can assist you further.",
          "Trouble Processing Your Schedule",
          'danger'
        );
        reject(new Error("Failed to update event"));
      }
    } catch (error) {
      clearTimeout(timeoutId);
      setIsLoadingTest(false);
      reject(error);
    } finally {
      clearTimeout(timeoutId);
      setIsLoadingTest(false);
      setMoveEventQueue((prevQueue) => prevQueue.slice(1)); 
      setIsProcessingMove(false);
    }
  }, [isProcessingMove, moveEventQueue, myEvents, user.authId, accessToken, setMyEvents, showAlert]);

  useEffect(() => {
    if (!isProcessingMove && moveEventQueue.length > 0) {
      processEventQueue();
    }
  }, [moveEventQueue, isProcessingMove, processEventQueue]);

  const moveEvent = async ({ event, start, end, allDay: isAllDay }) => {
    const existingEvent = myEvents.find((ev) => ev.eventId === event.eventId);
    const filteredState = myEvents.filter((ev) => ev.eventId !== event.eventId);

    existingEvent.isViableOrg = EventStatus.NOT_TESTED;
    existingEvent.isViableDest = EventStatus.NOT_TESTED;

    setMyEvents([...filteredState, { ...existingEvent, start, end, isAllDay }]);
    return new Promise((resolve, reject) => {
      setMoveEventQueue((prevQueue) => [...prevQueue, { event, start, end, isAllDay, resolve, reject }]);
    })
  }

  const handleDragStart = useCallback(
    (name, address, coordinates, additional, eventId) => {
      setDraggedClient({client: name, address: address, coordinates: coordinates, additional: additional, eventId: eventId});
  },[]);

  const getSelectedDaySchedule = () => {
    const viewStart = new Date(viewStartDate).getTime();
    const viewEnd = new Date(viewEndDate).getTime();

    const selectedDaySchedule = myEvents.filter((event) => {
      const day = event.start.toLocaleString('en-US', { weekday: 'long' });

      const start = new Date(event.start).getTime();

      return day === testDay && (start >= viewStart && start <= viewEnd);
    }).sort((a, b) => a.start - b.start);

    return selectedDaySchedule;
  }
  
  const testSchedule = async () => {
    const selectedDaySchedule = getSelectedDaySchedule();
    const needsTesting = selectedDaySchedule.filter((ev) => ev.isViableDest === EventStatus.NOT_TESTED && ev.isViableOrg === EventStatus.NOT_TESTED);

    if(selectedDaySchedule.length === 0) {
      showAlert(
        `No patients to test on ${testDay}. You're free!`, 
        "You're Free", 
        'warning'
      );
    } else if(needsTesting.length > 0) {
        if(selectedDaySchedule.length > 1) {
          setIsLoadingTest(true);
          setTestDay(undefined);
          const response = await getTimeDistances(selectedDaySchedule, user.bufferTime, accessToken);
          if(response.status === 200) {
            eventViability(response.message);
          } else {
            setIsLoadingTest(false);
            showAlert(
              `We ran into an error while testing your schedule. Please try again and provide feedback. Error Message: ${response.message}`, 
              "Oops!", 
              'danger'
            );
          }
        } else {
          showAlert(
            `Please make sure there are at least 2 patients on ${testDay}.`, 
            "Wait a Sec!", 
            'warning'
          );
        }
    } else {
        showAlert(
          `Looks like ${testDay} has already been tested with no changes`, 
          "Wait a Sec!", 
          'warning'
        );
    }
    
  }

  const clearScheduledDay = async () => {
    const selectedDaySchedule = getSelectedDaySchedule();
    const response = await clearScheduleFromDay(accessToken, selectedDaySchedule);
    if(response.status ===204) {
      await handleRefetch();
    } else {
      showAlert(
        "We're unable to remove your current schedule. Please try refreshing your page. If this issue persists, kindly provide us with feedback. Thanks for your patience.", 
        "Oh, Snap", 'danger'
      );
    }
  }

  const handleCloseModal = () => {
    setShowEventModal(false);
    setShowSchedulePrefModal(false);
    setShowTutorialModal(false);
  }

  const handleRehydratePrefs = () => {
    handleCloseModal();
    window.location.reload();
  }

  const removeEventFromCal = async () => {
    const response = await deleteEvent(user.authId, eventModalProps.client, accessToken);
    if(response.status === 204) {

      const viewStart = new Date(viewStartDate).getTime();
      const viewEnd = new Date(viewEndDate).getTime();
      const dateStr = eventModalProps.client.start; // Input string
      const [datePart, timePart] = dateStr.split(' at '); // Split into date and time
      const [month, day, year] = datePart.split('-'); // Split date into components
      const [hours, minutes] = timePart.split(':'); // Split time into components

      const date = new Date(year, month - 1, day, hours, minutes);
      const removeFromDay = date.toLocaleString('en-US', { weekday: 'long' });
  
      const scheduleOfMovedFrom = myEvents.filter((ev) => {
        const day = ev.start.toLocaleString('en-US', { weekday: 'long' });
  
        const start = new Date(ev.start).getTime();
  
        return day === removeFromDay && (start >= viewStart && start <= viewEnd);
      }).sort((a, b) => a.start - b.start);

      await updateViability(scheduleOfMovedFrom, eventModalProps.client);

      showAlert('', 'Success!',  'success');
      setTimeout(() => {
        handleCloseModal();
        handleRefetch();
      }, 500);
    } else {
      showAlert(`ERROR: ${response.message}`, 'Oh, Snap!', 'danger');
    }
  }

  const selectEvent = async(event) => {
    if(!event._id) {
      const response = await getUserSchedule(user.authId, accessToken);
      if(response.status === undefined) {
        const matchedEvent = response.find((ev) => ev.eventId === event.eventId);
        event._id = matchedEvent._id;
      } else {
        showAlert(
          "We're experiencing difficulty retrieving this patient's data. Please refresh your page and try again. If the problem continues, kindly provide us with feedback so we can assist you further.", "Oh, Snap!", 'danger'
        );
      }
    }
    
    const date = new Date(event.start);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; 
    const day = date.getDate();
    let hours = date.getHours();
    let minutes = date.getMinutes();

    if(minutes === 0) {
      minutes = '00'
    }

    if(hours === 0) {
      hours = '12'
    }
  
    const clientcalendarCheck = {
      title: event.title,
      address: event.address,
      coordinates: event.coordinates,
      id: event._id,
      eventId: event.eventId,
      start: `${month}-${day}-${year} at ${hours}:${minutes}`,
    }
    
    const patientProps = {
      client: clientcalendarCheck,
      myEvents: myEvents,
      patients: homes.message,
      view: viewFocus.view
    }

    setEventModalProps(patientProps);
    setShowEventModal(true);
  }  

  const daySelection = (event) => {
    switch (event) {
      case 'Sunday':
        setTestDay('Sunday');
        break;
      case 'Monday':
        setTestDay('Monday');
        break;
      case 'Tuesday':
        setTestDay('Tuesday');
        break;
      case 'Wednesday':
        setTestDay('Wednesday');
        break;
      case 'Thursday':
        setTestDay('Thursday');
        break;
      case 'Friday':
        setTestDay('Friday');
        break;
      case 'Saturday':
        setTestDay('Saturday');
        break;
      default:
        setTestDay(undefined);
        break;
    }
  }

  const parseTimeToScrollTime = ()  => {
    const parts = therapistParameters.startTime.split(':');
    const hours = parseInt(parts[0], 10);
    const minutes = parts.length > 1 ? parseInt(parts[1], 10) : 0;
  
    return new Date(1970, 1, 1, hours, minutes);
  }

  if(hasError) {
    return <ErrorScreen />
  }

  if(stillFetching) {
    return <Loading />
  }

  return(
    <div className={`${isLoadingTest ? "modal-open" : ""} ${styles.overall} p-3`}>
      <div className={`${isLoadingTest ? "modal-wrapper" : "d-none"}`}><LoadingModal /></div>
      <div id={styles.map} className='d-flex align-items-center justify-content-center'>
        {
          isGroupingOn ? <GroupingMap /> : <GeneralMap />
        }
        
      </div>
      <div className={`${isMapExpanded ? 'd-none' : `${styles.calendarContainer} d-lg-inline d-none`}`}>
        <div className='d-flex justify-content-between'>
          <button onClick={handleSchedulePreferences} className="transparentBtnSecondary">Schedule Preferences</button>
          <button onClick={handleTutorial} className="transparentBtnSecondary d-flex justify-content-center align-items-center"><img className='me-1' src="/assets/icons/Info.svg" alt="info" style={{ width: '15px', height: '15px' }} /> Scheduling Help</button>
        </div>
        <DnDCalendar {...props} 
          localizer={localizer} 
          events={myEvents} 
          onDropFromOutside={onDropFromOutside}
          onEventDrop={handleMoveEvent}
          onEventResize={handleMoveEvent}
          eventPropGetter={eventPropGetter}
          onSelectEvent={selectEvent}
          scrollToTime={parseTimeToScrollTime()}
          step={5}
          defaultView="week" 
          onNavigate={handleNavigate}
          onView={changeView}
          resizable
          selectable
        /> 
      </div>
      <div className={`${styles.patientsContainer} p-2`}>
        <div className={`${isMapExpanded ? 'd-none' : 'd-flex flex-column justify-content-start align-items-center w-100 mb-4 d-lg-inline d-none'}`}>
          <div className='d-flex justify-content-start align-items-start mb-3 w-100'>
            <select
              className={styles.select} 
              name="testDay" id="testDay" 
              onChange={(event) => daySelection(event.target.value)}
              value={testDay || ''}
            >
              <option disabled value="">Select a Day to Test</option>
                <option value="Sunday">Sunday</option>
                <option value="Monday">Monday</option>
                <option value="Tuesday">Tuesday</option>
                <option value="Wednesday">Wednesday</option>
                <option value="Thursday">Thursday</option>
                <option value="Friday">Friday</option>
                <option value="Saturday">Saturday</option>
            </select>
          </div>
          <div className='d-flex align-items-center justify-content-between w-100'>
            <button 
              onClick={testSchedule} 
              className="primaryBtn ms-2" 
              disabled={!testDay}>
              Test Day
            </button>
            <button 
              onClick={clearScheduledDay} 
              className="tertiaryBtn ms-2" 
              disabled={!testDay}>
              Clear Day
            </button>
          </div>
        </div>
        <PatientScheduleList handleDragStart={handleDragStart} myEvents={myEvents} start={viewStartDate} end={viewEndDate} />
      </div>
      <ModalComponent 
        title={"Manage Your Visit"}
        component={<EventModal modalProps={eventModalProps} />}
        show={showEventModal}
        handleAction={removeEventFromCal}
        actionTitle={'Remove Visit'}
        buttonClass={"tertiaryBtn"}
        handleClose={handleCloseModal}
      />
      <FloatingModal 
        title="Schedule Preferences" 
        component={<SchedulePreferences handleClose={handleRehydratePrefs}  />} 
        show={showSchedulePrefModal} 
        handleClose={handleCloseModal}
      />
      <FloatingModal 
        title="Scheduling Help" 
        component={<ScheduleTutorial />} 
        show={showTutorialModal} 
        handleClose={handleCloseModal}
      />
    </div>
  );
 
}

export default Calendar;