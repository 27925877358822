import React from "react";

import Accordion from 'react-bootstrap/Accordion';
import styles from "./Modal_Components.module.css"

const FAQs = () => {
  return (
    <Accordion className="custom-accordion">
      <Accordion.Item eventKey="0">
        <Accordion.Header><span className={styles.accordionHeader}>What is the core functionality of MySchedulePal?</span></Accordion.Header>
        <Accordion.Body>
          <div>
            <p className="pb-1">Currently, MySchedulePal lets you schedule your day and test the drive-time viability of your plan. In other words, you can check if you’ll have enough time to get from Patient A to Patient B with the schedule you’ve set. This feature saves you the hassle of switching between Google Maps and your planner, making adjustments quick and easy.</p>
            <p>Soon, we’ll be launching automatic geographic grouping of patients and route optimization to streamline your scheduling even further. And this is just the beginning—there’s so much more to come. We’re overflowing with ideas and excited to share them with you!</p> 
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header><span className={styles.accordionHeader}>Can I use MySchedulePal on my phone or tablet?</span></Accordion.Header>
        <Accordion.Body>
        <div>
          <p className="pb-1">
            For now, we recommend using MySchedulePal on your computer. A mobile app is in the works and coming soon!
          </p>
          <p>
            You can use your phone or tablet if you’d like, but the experience and features are a bit limited at this point. We prioritized releasing the desktop version first because we wanted to get the tool into your hands as quickly as possible. Rest assured, we’re hard at work on the mobile version!
          </p>
        </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header><span className={styles.accordionHeader}>Is MySchedulePal HIPAA compliant?</span></Accordion.Header>
        <Accordion.Body>
        <div>
          <p className="pb-1">
            Heck yeah! Jumping through those hoops wasn’t easy, but we made it!
          </p>
          <p>
            In all seriousness, yes, MySchedulePal is HIPAA compliant. We’ve implemented end-to-end encryption and secured all the necessary BAAs. Rest assured, we’ve got you covered.
          </p>
        </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header><span className={styles.accordionHeader}>How do I schedule a patient?</span></Accordion.Header>
        <Accordion.Body>
          <div>
            <ol>
              <li>After you've created at least 2 patients, head over to the scheduling tab.</li>
              <li>Click and hold the patient you'd like to schedule.</li>
              <li>Drag the patient's name over to the calendar.</li>
              <li>Release the patients name over the spot where you'd like to schedule them.</li>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header><span className={styles.accordionHeader}>How do I move the patient from one time to another?</span></Accordion.Header>
        <Accordion.Body>
          <div>
            <ol>
              <li>Click and hold the event you'd like to move on the calendar.</li>
              <li>Drag the event to the desired spot and release.</li>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header><span className={styles.accordionHeader}>How do I test my schedule?</span></Accordion.Header>
        <Accordion.Body>
          <div>
            After scheduling at least 2 patients, you can test the drive-time viability for the day. Select the desired day in the upper right corner and click "Test Day". <span style={{color: "var(--viable)", fontWeight: "600"}}>Green</span> indicates viable drive times, while <span style={{color: "var(--notViable)", fontWeight: "600"}}>Red</span> means adjustments are needed due to insufficient time. If you need to make adjustments drag your patient to a new spot and test again.
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header><span className={styles.accordionHeader}>What do the colors mean after I've tested?</span></Accordion.Header>
        <Accordion.Body>
          <div>
            <ul>
              <li className="mb-2"><span style={{color: "var(--viable)", fontWeight: "600"}}>Green:</span> This indicates that there is enough time to get from point A to point B, accounting for the allotted buffer time.</li>
              <li><span style={{color: "var(--notViable)", fontWeight: "600"}}>Red:</span> This indicates that you do not have enough time to get from A to B on time. You'll need to adjust by dragging the event into a new slot.</li>
            </ul>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default FAQs;