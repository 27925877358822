import { createAsyncThunk } from '@reduxjs/toolkit';
import usePatientRequests from '../../../hooks/patient-requests';

const { getPatients } = usePatientRequests();

export const fetchPatients = createAsyncThunk(
  'patients/fetchPatients', 
  async ({ user, accessToken }, { rejectWithValue }) => {
    try {
      const patientData = await getPatients(user.authId, accessToken);
      if (patientData.status === 500) {
        throw new Error(patientData.message);
      }
      return patientData.message;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

