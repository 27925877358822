import { useState, useRef, useEffect } from "react";
import { useAlert } from "../context/alert-context";

import { LocationClient, SearchPlaceIndexForSuggestionsCommand } from '@aws-sdk/client-location';
import usePatientRequests from "../hooks/patient-requests";
import Loading from "../pop-ups/loading";

import { Form, Badge, CloseButton, Button, Row, Col, Container} from 'react-bootstrap';
import styles from "./Modal_Components.module.css";

const EditPatient = ({patient, accessToken, userId, handleClose}) => {
  const access_Key_Id = process.env.REACT_APP_AWS_ACCESS_KEY_ID ;
  const secret_Access_Key = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
  const [patientData, setPatientData] = useState(patient);
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const suggestionsRef = useRef(null);
  const { updatePatient } = usePatientRequests();
  const { showAlert, hideAlert } = useAlert();

  const handleInputChange = (event) => {
    const {name, value} = event.target;

    setPatientData({
        ...patientData,
        [name]: value
    });
  }

  const client = new LocationClient({
    region: 'us-east-1',
    credentials: {
      accessKeyId: access_Key_Id,
      secretAccessKey:secret_Access_Key
    }
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
            setUserLocation([position.coords.longitude, position.coords.latitude]);
        },
        (error) => {
            console.error("Error getting user location:", error);
        }
      );
    } else {
      setUserLocation([user.coordinates.longitude, user.coordinates.latitude]);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
        closeSuggestions();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closeSuggestions = () => setSuggestions([]);

  const fetchSuggestions = async (input) => {
    const command = new SearchPlaceIndexForSuggestionsCommand({
        IndexName: 'MySchedulePal-PI',
        Text: input,
        MaxResults: 5,
        BiasPosition: userLocation
    });

    try {
      const response = await client.send(command);
      const suggestionTexts = response.Results.map(suggestion => suggestion.Text);
      setSuggestions(suggestionTexts);
    } catch (error) {
        console.error('Error fetching suggestions:', error);
    }
  }

  const handleAddressChange = (e) => {
    const value = e.target.value;
    setPatientData(prev => ({
      ...prev,
      address: value
    }));

    if (value) {
        fetchSuggestions(value);
    } else {
        setSuggestions([]);
    }
  }

  const handleSelectAddress = (addressSelected) => {
    setPatientData(prev => ({
      ...prev,
      address: addressSelected
    }));
    closeSuggestions()
  }

  const handleNoSeeDays = (value) => {
    setPatientData((prev) => ({
      ...prev,
      noSeeDays: {
        ...prev.noSeeDays,
        [value]: !prev.noSeeDays[value]
      }
    }));
  }

  const handleSave = async () => {
    // const result = await showConfirm();

    const response = await updatePatient(patientData, accessToken);
    if(response.status === 201) {
      setIsLoading(true);
      showAlert('The updated patient info was saved successfully.', 'Success!',  'success');
      setTimeout(() => {
        handleClose()
      }, 800);

    } else {
      showAlert(`ERROR: ${response.message}`, 'Oh, Snap!', 'danger');;
    }
  }
  
  if(isLoading) {
    return (
      <Container fluid className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <Loading />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <div className="p-1 p-md-3 d-flex flex-column justify-content-start align-items-start">
    <div className="d-flex flex-column flex-lg-row my-2">
      <div className="d-flex m-2 align-items-start justify-content-start flex-column">
        <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">*First Name</label>
        <input className="mspInput" type="text" name="firstName" value={patientData.firstName} onChange={handleInputChange} placeholder="Jane" />
      </div>
      <div className="d-flex m-2 align-items-start justify-content-start flex-column">
        <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">*Last Name</label>
        <input className="mspInput" type="text" name="lastName" value={patientData.lastName} onChange={handleInputChange} placeholder="Doe" />
      </div>
    </div>
    <div className="d-flex justify-content-start align-items-center my-2">
      <div className="d-flex flex-column m-2 justify-content-start align-items-start">
        <label htmlFor="first" className="mspLabel address long d-flex justify-content-start align-items-start">*Address</label>
        <input
          type="text"
          value={patientData.address}
          onChange={handleAddressChange}
          className="mspInput address long"
          placeholder="Start typing address..."
        />
        <div ref={suggestionsRef} className={`${suggestions.length !== 0 ? `${styles.suggestionsContainer}` : "d-none"}`}>
          <ul>
            {suggestions.map((suggestion, index) => (
                <li className={styles.addressItem} key={index} onClick={() => handleSelectAddress(suggestion)}>{suggestion}</li>
            ))}
          </ul>
        </div> 
      </div>
    </div>
    <div className={`d-flex flex-column`}>
      <div className="d-flex flex-column flex-lg-row my-2">
        <div className="d-flex m-2 align-items-start justify-content-start flex-column">
          <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">Primary Phone</label>
          <input className="mspInput" type="tel" name="primaryNumber" value={patientData.primaryNumber} onChange={handleInputChange} placeholder="123-456-7891" />
        </div>
        <div className="d-flex m-2 align-items-start justify-content-start flex-column">
          <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">Email</label>
          <input className="mspInput" type="text" name="email" value={patientData.email} onChange={handleInputChange} placeholder="jane@example.com" />
        </div>
      </div>
      <div className="d-flex flex-column flex-lg-row my-2">
        <div className="d-flex m-2 align-items-start justify-content-start flex-column">
          <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">Secondary Phone</label>
          <input className="mspInput" type="tel" name="secondaryNumber" value={patientData.secondaryNumber} onChange={handleInputChange} placeholder="123-456-7891" />
        </div>
        <div className="d-flex m-2 align-items-start justify-content-start flex-column">
          <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">Frequency</label>
          <div className={styles.inputWrapper}>
            <input type="number" className={styles.inputFlex} name="frequency" value={patientData.frequency} onChange={handleInputChange} placeholder="2" />
            <span className={`${styles.labelFlex} px-2`}>/Week</span>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column flex-lg-row my-2">
        <div className="d-flex m-2 align-items-start justify-content-start flex-column">
          <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">Days Pt Cannot Be Seen</label>
          <select onChange={(event) => handleNoSeeDays(event.target.value)} name="noseeDays" id="noseeDays" className="mspInput">
          <option disabled defaultValue={"Select Days"} value="">Select Days</option>
            <option className={`${patientData.noSeeDays.sunday ? 'd-none' : null}`} value="sunday">
              Sunday
            </option>
            <option className={`${patientData.noSeeDays.monday ? 'd-none' : null}`} value="monday">
              Monday
            </option>
            <option className={`${patientData.noSeeDays.tuesday ? 'd-none' : null}`} value="tuesday">
              Tuesday
            </option>
            <option className={`${patientData.noSeeDays.wednesday ? 'd-none' : null}`} value="wednesday">
              Wednesday
            </option>
            <option className={`${patientData.noSeeDays.thursday ? 'd-none' : null}`} value="thursday">
              Thursday
            </option>
            <option className={`${patientData.noSeeDays.friday ? 'd-none' : null}`} value="friday">
              Friday
            </option>
            <option className={`${patientData.noSeeDays.saturday ? 'd-none' : null}`} value="saturday">
              Saturday
            </option>
          </select>
        </div>
        <div className="d-flex flex-wrap">
          {
            Object.entries(patientData.noSeeDays).map(([propertyName, propertyValue]) => (
              propertyValue && (
              <div className='m-2' key={propertyName}>
                <Badge className={`${styles.customBadge} d-flex justify-content-start align-items-center`} id={styles.customBadge}>
                  <CloseButton className={`${styles.xBtn} me-2`} onClick={() => handleNoSeeDays(propertyName)} /> 
                  {propertyName}
                </Badge>
              </div>
            )))
          }
        </div>
      </div>
    </div>
    <div className="d-flex align-items-center justify-content-end w-100 mt-3">
      <button onClick={handleSave} className="primaryBtn">Save</button>
    </div>
  </div>
  );
};

export default EditPatient;  


