import React from "react";
import { UserContext, AccessTokenContext } from '../../context/context.js';
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import YouTube from 'react-youtube';

import styles from "./Dashboard.module.css";

import useScheduleRequests from "../../hooks/schedule-requests.js";
import Loading from "../../pop-ups/loading.js";
import useUserRequests from "../../hooks/user-requests.js";
import ErrorScreen from "../../static/Error/Error_Screen.js";

const Dashboard = () => {
  const user = useContext(UserContext);
  const accessToken = useContext(AccessTokenContext);
  const { getUserSchedule } = useScheduleRequests();
  const { getOnboardingData } = useUserRequests();
  const navigate = useNavigate();
 
  const [schedule, setSchedule] = useState(undefined);
  const [onboardingData, setOnboardingData] = useState({eventsCreated: false, eventsTested: false, patientsCreated: false});
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null); 

  useEffect(() => {
    localStorage.clear();
  },[]);

  useEffect(() => {
    const onboardingComplete = localStorage.getItem('onboardingComplete') === 'true';
    if(!onboardingComplete) {
      fetchData();
    } else {
      setOnboardingData({eventsCreated: true, eventsTested: true, patientsCreated: true})
      findTodayVisits();
    }
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true); 
      const response = await getOnboardingData(user.authId, accessToken);
      setOnboardingData(response); 
      if(response.eventsCreated && response.patientsCreated && response.eventsTested) {
        localStorage.setItem('onboardingComplete', 'true');
        findTodayVisits();
      } else {
        setLoading(false);
      }
    } catch (err) {
      setError(err);
      setLoading(false); 
    } 
  }

  const dateUniformer = (timeStamp) => {
    const date = timeStamp ? new Date(timeStamp) : new Date();
    const dateValues = {
      dateYear: date.getFullYear(),
      dateMonth: date.getMonth() + 1, 
      dateDay: date.getDate(),
    };

    return dateValues;
  };
  
  const findTodayVisits = async () => {
    try {
      const response = await getUserSchedule(user.authId, accessToken);
      const today = dateUniformer(undefined);
    
      const currentEvents = response.filter((event) => {
        const eventDate = dateUniformer(event.start);
        return today.dateYear === eventDate.dateYear && today.dateMonth === eventDate.dateMonth && today.dateDay === eventDate.dateDay;
      }).sort((a, b) => new Date(a.start) - new Date(b.start));

      setSchedule(currentEvents);

    } catch (err) {
      setError(err); 
    } finally {
      setLoading(false);
    }
  };

  const formatTime = (isoString) => {
    const date = new Date(isoString);
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return date.toLocaleTimeString('en-US', options);
  }

  const handleNavigate = (location) => {
    navigate(location);
  };

  const eventRow = (event, index) => {
    return (
      <div className={styles.eventButtonContainer}>
        <button onClick={() => handleNavigate("/schedule")} 
          className={`
            ${styles.eventButton} 
            ${event.isViableDest === "confirmed" ? styles.viableDest : event.isViableDest === "not_tested" ? styles.viableDestNull : styles.viableDestFalse} 
            ${event.isViableOrg === "confirmed" ? styles.viableOrg : event.isViableOrg === "not_tested" ? styles.viableOrgNull : styles.viableOrgFalse}
          `}
        >
          <div className={styles.eventTitle}>{event.title}</div>
          <div className={styles.eventText}>{formatTime(event.start)}</div>
          <div className={styles.eventText}>{event.address}</div>
        </button>
        <div className="d-flex justify-content-center align-items-center"><img src="/assets/icons/Car_Side.svg" alt="Next" width="25" height="25" /></div>
        <button onClick={() => handleNavigate("/schedule")} className={`${(schedule.length -1) === index ? 'd-flex' : 'd-none'} ${styles.eventButton} ${styles.eventHome}`}>
          <div className={styles.eventTitle}>Home</div>
          <div className={styles.eventText}>Have a nice night!</div>
          <div className={styles.eventText}>Drive Safe</div>
        </button>
        <div className={`${(schedule.length -1) === index ? 'd-flex' : 'd-none'} d-flex justify-content-center align-items-center`}><img src="/assets/icons/Home.svg" alt="Complete" width="25" height="25" /></div>
      </div>
    );
  }

  if (loading) return <Loading />;

  if (error) return <ErrorScreen errorMessage={error.message}/>;

  if(!onboardingData.eventsCreated || !onboardingData.patientsCreated || !onboardingData.eventsTested) {
    return (
      <div className="p-2 p-md-3 p-lg-5 d-flex flex-column">
        <div className={`${styles.titleContainer} mb-3 d-flex justify-content-start align-items-start`}>
          <div className="titlePrimary">Today's Visits</div>
        </div>
        <div className={styles.onboardContainer}>
          <div className={styles.videoContainer}>
            <YouTube videoId="7SSRjHgS6ts" opts={{playerVars: {autoplay: 1,},}} />
          </div>
          <div className={styles.explanation}>Quickly view your schedule at a glance right here on the dashboard. Simply follow the prompts below to get started!</div>
        </div>
        <div className={styles.mainOnboardContainer}>
          <div className="d-flex justify-content-center align-items-center flex-wrap">
            <button onClick={() => handleNavigate('/patients')} className={`${onboardingData.patientsCreated ? `${styles.taskCompleted}` : null} ${styles.stepBtn}`}>
              <div className={styles.stepNum}>1</div>
              <div className={styles.stepName}>Create your first 2 patients</div>
              <div className={styles.stepIcon}>
                <img className={`${!onboardingData.patientsCreated ? 'd-inline': 'd-none'}`} src="/assets/icons/Forward_Button.svg" alt="Go" width="25" height="25" />
                <img className={`${!onboardingData.patientsCreated ? 'd-none': 'd-inline'}`} src="/assets/icons/Check_Secondary.svg" alt="Complete" width="25" height="25" />
              </div>
            </button>
            <button onClick={() => handleNavigate('/schedule')} className={`${onboardingData.eventsCreated ? `${styles.taskCompleted}` : null} ${styles.stepBtn}`}>
              <div className={styles.stepNum}>2</div>
              <div className={styles.stepName}>Drag & drop your patients onto the schedule</div>
              <div className={styles.stepIcon}>
                <img className={`${!onboardingData.eventsCreated ? 'd-inline': 'd-none'}`} src="/assets/icons/Forward_Button.svg" alt="Go" width="25" height="25" />
                <img className={`${!onboardingData.eventsCreated ? 'd-none': 'd-inline'}`} src="/assets/icons/Check_Secondary.svg" alt="Complete" width="25" height="25" />
              </div>
            </button>
            <button onClick={() => handleNavigate('/schedule')} className={`${onboardingData.eventsTested ? `${styles.taskCompleted}` : null} ${styles.stepBtn}`}>
              <div className={styles.stepNum}>3</div>
              <div className={styles.stepName}>Select the day & click test</div>
              <div className={styles.stepIcon}>
                <img className={`${!onboardingData.eventsTested ? 'd-inline': 'd-none'}`} src="/assets/icons/Forward_Button.svg" alt="Go" width="25" height="25" />
                <img className={`${!onboardingData.eventsTested ? 'd-none': 'd-inline'}`} src="/assets/icons/Check_Secondary.svg" alt="Complete" width="25" height="25" />
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-2 p-md-3 p-lg-5 d-flex flex-column">
      <div className={`${styles.titleContainer} mb-3 d-flex justify-content-start align-items-start`}>
        <div className="titlePrimary">Today's Visits</div>
      </div>
      <div className={`${schedule.length !== 0 ? 'd-flex' : 'd-none'} ${styles.eventsContainer}`}>
        {schedule.map((event, index) => (
          eventRow(event, index)
        ))}
      </div>
      <div className={`${schedule.length !== 0 ? 'd-none' : 'd-flex'} ${styles.eventsContainer}`}>
        <img src="/assets/images/Vacation.png" alt="No Visits" width={300} height={300} />
        <div className={styles.explanation}>Looks like your free today, time to party!</div>
      </div>
    </div>
  );
 
};

export default Dashboard;

 