import { createSlice } from '@reduxjs/toolkit';
import { fetchPatients } from './Patients_Thunks.js'; 

const patientsSlice = createSlice({
  name: 'patients',
  initialState: { list: [], status: 'idle', error: null, isGroupingOn: false, selectedPatientId: '', isMapExpanded: false},
  reducers: {
    toggleGrouping: (state) => {
      state.isGroupingOn = !state.isGroupingOn;
    },
    selectPatientId: (state, action) => {
      state.selectedPatientId = action.payload;
    },
    toggleMapExpansion: (state) => {
      state.isMapExpanded = !state.isMapExpanded;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPatients.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPatients.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.list = [...action.payload]; 
      })
      .addCase(fetchPatients.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload; 
      });
  },
});

export const { toggleGrouping, selectPatientId, toggleMapExpansion } = patientsSlice.actions;
export default patientsSlice.reducer;

