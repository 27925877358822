import React, { useState, useContext } from "react";

import { UserContext } from '../../context/context';
import { AccessTokenContext } from "../../context/context";
import { useAuth0 } from "@auth0/auth0-react";

import FloatingModal from '../../static/Modals/Floating_Modal.js';
import EditUser from "../../Modal_Components/Edit_User.js";
import Feedback from "../../Modal_Components/Feedback";

import styles from "./Profile.module.css";

const Profile = () => {
  const user = useContext(UserContext);
  const accessToken = useContext(AccessTokenContext);
  const { logout } = useAuth0();

  const [showFeedback, setShowFeedback] = useState(false);
  const [showEditInfo, setShowEditInfo] = useState(false);

  const handleClose = () => {
    setShowFeedback(false);
    setShowEditInfo(false);
  }

  const handleFeedback = () => setShowFeedback(true);
  const handleShowEdit = () => setShowEditInfo(true);

  return (
    <div className="p-3 p-md-5">
      <div className={styles.overallContainer}>
        <div className={styles.infoContainer}>
          <div className={styles.basicInfo}>
            <div className={styles.header}>
              <div className={styles.title}>My Info</div>
              <button onClick={handleShowEdit} className="transparentBtnPrimary">Edit</button>
            </div>
            <div className="p-3 d-flex flex-column w-100">
              <div className={`${user.firstName ? "d-flex" : "d-none"} mb-3 d-flex align-items-center justify-content-between`}>
                <div className={styles.infoLabel}>Name</div>
                <div className={styles.infoDiv}>{user.firstName} {user.lastName}</div>
              </div>
              <div className={`${user.primaryNumber ? "d-flex" : "d-none"} mb-3 d-flex align-items-center justify-content-between`}>
                <div className={styles.infoLabel}>Number</div>
                <div className={styles.infoDiv}>{user.primaryNumber}</div>
              </div>
              <div className={`${user.designation ? "d-flex" : "d-none"} mb-3 d-flex align-items-center justify-content-between`}>
                <div className={styles.infoLabel}>My Role</div>
                <div className={styles.infoDiv}>{user.designation}</div>
              </div>
              <div className={`${user.email ? "d-flex" : "d-none"} mb-3 d-flex align-items-center justify-content-between`}>
                <div className={styles.infoLabel}>Email</div>
                <div className={styles.infoDiv}>{user.email}</div>
              </div>
              <div className={`mb-3 d-flex align-items-center justify-content-between`}>
                <div className={styles.infoLabel}>Starting Point</div>
                <div className={styles.infoDiv}>{user.address}</div>
              </div>
              <div className={`mb-3 d-flex align-items-center justify-content-between`}>
                <div className={styles.infoLabel}>Start Time</div>
                <div className={styles.infoDiv}>{user.startTime}</div>
              </div>
              <div className={`mb-3 d-flex align-items-center justify-content-between`}>
                <div className={styles.infoLabel}>Session Length</div>
                <div className={styles.infoDiv}>{user.sessionLength} <span className={styles.infoLabel}>Min</span></div>
              </div>
              <div className={`mb-3 d-flex align-items-center justify-content-between`}>
                <div className={styles.infoLabel}>Buffer Time</div>
                <div className={styles.infoDiv}>{user.bufferTime} <span className={styles.infoLabel}>Min</span></div>
              </div>
              <div className={`mt-5 d-flex align-items-center justify-content-between`}>
                <button onClick={handleFeedback} className="primaryBtn">Feedback</button>
                <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} className="tertiaryBtn">Logout</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FloatingModal 
        title="My Profile" 
        component={<EditUser user={user} accessToken={accessToken} handleClose={handleClose} />} 
        show={showEditInfo} 
        handleClose={handleClose}
      />
      <FloatingModal 
        title="Feedback" 
        component={<Feedback handleClose={handleClose} user={user}/>} 
        show={showFeedback} 
        handleClose={handleClose}
      />
    </div>
   
  );
};

export default Profile;