import { useEffect, useState } from "react";
import { Slider } from "@mui/material";
import styles from './Modal_Components.module.css';
const CustomizeGroups = ({handleClose, handleSave}) => {
  const [clusters, setClusters] = useState(5);
  const [size, setSize] = useState(2);

  const clusterMarks = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
  ];

  const sizeMarks = [
    { value: 1, label: 'Small' },
    { value: 2, label: 'Medium' },
    { value: 3, label: 'Large' },
  ];

  useEffect(() => {
    const storedCustomization =  JSON.parse(localStorage.getItem('groupCustomization'));
    setClusters(storedCustomization.clusters);
    setSize(storedCustomization.size);
  }, []);
  

  const handleClusterChange = (event, newValue) => {
    setClusters(newValue);
  }

  const handleSizeChange = (event, newValue) => {
    setSize(newValue);
  }

  const handleSaveToStorage = () => {
    const storedCustomization =  JSON.parse(localStorage.getItem('groupCustomization'));
    storedCustomization.clusters = clusters;
    storedCustomization.size = size
    localStorage.setItem(
      'groupCustomization', 
      JSON.stringify(storedCustomization)
    );
    handleClose();
    handleSave();
  }


  return (
    <div className="p-1 p-md-3 d-flex flex-column">
      <div className={`${styles.rangeContainers} d-flex align-items-center justify-content-between my-2`}>
        <div className={`${styles.rangeContainer} d-flex align-items-start justify-content-start flex-column`}>
          <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">
            How many groups would you like to create?
          </label>
          <Slider
            aria-label="Temperature"
            value={clusters}
            onChange={handleClusterChange}
            valueLabelDisplay="auto"
            shiftStep={5}
            step={1}
            marks={clusterMarks}
            min={1}
            max={7}
            sx={{
              color: 'var(--primary)', // Change the track and thumb color
              height: 10, // Thickness of the slider track
              '& .MuiSlider-track': {
                border: '2px solid var(--primary)', // Adds a border to the track
              },
              '& .MuiSlider-thumb': {
                backgroundColor: 'var(--primary)', // Thumb color
                border: '2px solid #673AB7',
              },
              '& .MuiSlider-rail': {
                color: 'var(--card)',
                border: '2px solid var(--primary)' // Unfilled rail color
              },
              '& .MuiSlider-mark': {
                backgroundColor: 'var(--primary)', // Mark color
              },
              '& .MuiSlider-markActive': {
                backgroundColor: 'var(--card)', // Active mark color
              },
            }}
          />
        </div>
        <div className="d-flex align-items-start justify-content-start flex-column">
          <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">
            How big do you want your patients to be?
          </label>
          <Slider
            aria-label="Temperature"
            value={size}
            onChange={handleSizeChange}
            valueLabelDisplay="auto"
            shiftStep={5}
            step={1}
            marks={sizeMarks}
            min={1}
            max={3}
            sx={{
              color: 'var(--primary)', // Change the track and thumb color
              height: 10, // Thickness of the slider track
              '& .MuiSlider-track': {
                border: '2px solid var(--primary)', // Adds a border to the track
              },
              '& .MuiSlider-thumb': {
                backgroundColor: 'var(--primary)', // Thumb color
                border: '2px solid #673AB7',
              },
              '& .MuiSlider-rail': {
                color: 'var(--card)',
                border: '2px solid var(--primary)' // Unfilled rail color
              },
              '& .MuiSlider-mark': {
                backgroundColor: 'var(--primary)', // Mark color
              },
              '& .MuiSlider-markActive': {
                backgroundColor: 'var(--card)', // Active mark color
              },
            }}
          />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end w-100 mt-3">
        <button onClick={handleSaveToStorage} className="primaryBtn">Save</button>
      </div>
    </div>
  );
}

export default CustomizeGroups;