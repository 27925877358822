import { useState, useRef, useEffect } from "react";
import { useAlert } from "../context/alert-context";

import { LocationClient, SearchPlaceIndexForSuggestionsCommand } from '@aws-sdk/client-location';
import useUserRequests from "../hooks/user-requests";

import Loading from "../pop-ups/loading";

import { Row, Col, Container} from 'react-bootstrap';
import styles from "./Modal_Components.module.css";

const EditUser = ({user, accessToken, handleClose}) => {
  const access_Key_Id = process.env.REACT_APP_AWS_ACCESS_KEY_ID ;
  const secret_Access_Key = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
  const [userData, setUserData] = useState(user);
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const suggestionsRef = useRef(null);
  const { showAlert, hideAlert } = useAlert();
  const { updateUser } = useUserRequests();

  const handleInputChange = (event) => {
    const {name, value} = event.target;

    setUserData({
        ...userData,
        [name]: value
    });
  }

  const client = new LocationClient({
    region: 'us-east-1',
    credentials: {
      accessKeyId: access_Key_Id,
      secretAccessKey:secret_Access_Key
    }
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
            setUserLocation([position.coords.longitude, position.coords.latitude]);
        },
        (error) => {
            console.error("Error getting user location:", error);
        }
      );
    } else {
      setUserLocation([user.coordinates.longitude, user.coordinates.latitude]);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
        closeSuggestions();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closeSuggestions = () => setSuggestions([]);

  const fetchSuggestions = async (input) => {
    const command = new SearchPlaceIndexForSuggestionsCommand({
        IndexName: 'MySchedulePal-PI',
        Text: input,
        MaxResults: 5,
        BiasPosition: userLocation
    });

    try {
      const response = await client.send(command);
      const suggestionTexts = response.Results.map(suggestion => suggestion.Text);
      setSuggestions(suggestionTexts);
    } catch (error) {
        console.error('Error fetching suggestions:', error);
    }
  }

  const handleAddressChange = (e) => {
    const value = e.target.value;
    setUserData(prev => ({
      ...prev,
      address: value
    }));

    if (value) {
        fetchSuggestions(value);
    } else {
        setSuggestions([]);
    }
  }

  const handleSelectAddress = (addressSelected) => {
    setUserData(prev => ({
      ...prev,
      address: addressSelected
    }));
    closeSuggestions()
  }

  const handleSave = async () => {
    const response = await updateUser(user.authId, accessToken, userData);
    if(response.status === 200) {
      setIsLoading(true);
      showAlert('Your information was updated.', 'Success!',  'success');
      setTimeout(() => {
        window.location.reload();
      }, 800);

    } else {
      showAlert(`ERROR: ${response.message}`, 'Oh, Snap!', 'danger');;
    }
  }


  
  if(isLoading) {
    return (
      <Container fluid className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <Loading />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <div className="p-1 p-md-3 d-flex flex-column justify-content-start align-items-start">
    <div className="d-flex flex-column flex-lg-row my-2">
      <div className="d-flex m-2 align-items-start justify-content-start flex-column">
        <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">*First Name</label>
        <input className="mspInput" type="text" name="firstName" value={userData.firstName} onChange={handleInputChange} placeholder="Jane" />
      </div>
      <div className="d-flex m-2 align-items-start justify-content-start flex-column">
        <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">*Last Name</label>
        <input className="mspInput" type="text" name="lastName" value={userData.lastName} onChange={handleInputChange} placeholder="Doe" />
      </div>
    </div>
    <div className="d-flex justify-content-start align-items-center my-2">
      <div className="d-flex flex-column m-2 justify-content-start align-items-start">
        <label htmlFor="first" className="mspLabel address long d-flex justify-content-start align-items-start">*My Starting Point</label>
        <input
          type="text"
          value={userData.address}
          onChange={handleAddressChange}
          className="mspInput address long"
          placeholder="Start typing address..."
        />
        <div ref={suggestionsRef} className={`${suggestions.length !== 0 ? `${styles.suggestionsContainer}` : "d-none"}`}>
          <ul>
            {suggestions.map((suggestion, index) => (
                <li className={styles.addressItem} key={index} onClick={() => handleSelectAddress(suggestion)}>{suggestion}</li>
            ))}
          </ul>
        </div> 
      </div>
    </div>
    <div className={`d-flex flex-column`}>
      <div className="d-flex flex-column flex-lg-row my-2 flex-wrap">
        <div className="d-flex m-2 align-items-start justify-content-start flex-column">
          <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">Primary Phone</label>
          <input className="mspInput" type="tel" name="primaryPhone" value={userData.primaryPhone} onChange={handleInputChange} placeholder="123-456-7891" />
        </div>
        <div className="d-flex m-2 align-items-start justify-content-start flex-column">
          <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">Email</label>
          <input className="mspInput" type="text" name="email" value={userData.email} onChange={handleInputChange} placeholder="jane@example.com" />
        </div>
        <div className="d-flex m-2 align-items-start justify-content-start flex-column">
          <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">Start Time</label>
          <input className="mspInput" type="time" name="startTime" value={userData.startTime} onChange={handleInputChange} />
        </div>
      </div>
      <div className="d-flex flex-column flex-lg-row my-2 flex-wrap">
        <div className="d-flex m-2 align-items-start justify-content-start flex-column">
          <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">Session Length</label>
          <div className={styles.inputWrapper}>
            <input type="number" className={styles.inputFlex} name="sessionLength" value={userData.sessionLength} onChange={handleInputChange} placeholder="50" />
            <span className={`${styles.labelFlex} px-2`}>min</span>
          </div>
        </div>
        <div className="d-flex m-2 align-items-start justify-content-start flex-column">
          <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">Buffer Time</label>
          <div className={styles.inputWrapper}>
            <input type="number" className={styles.inputFlex} name="bufferTime" value={userData.bufferTime} onChange={handleInputChange} placeholder="5" />
            <span className={`${styles.labelFlex} px-2`}>min</span>
          </div>
        </div>
      </div>
    </div>
    <div className="d-flex align-items-center justify-content-end w-100 mt-3">
      <button onClick={handleSave} className="primaryBtn">Save</button>
    </div>
  </div>
  );
};

export default EditUser;  