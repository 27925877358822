import React from "react";
import { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';

import usePatientRequests from "../hooks/patient-requests";
import { useAlert } from "../context/alert-context";
import Loading from "../pop-ups/loading";

import styles from "./Modal_Components.module.css"

const CreateNote = ({userId, accessToken, patientData, closeModal}) => {
  const { updatePatient } = usePatientRequests();
  const { showAlert } = useAlert();

  const [patientNote, setPatientNote] = useState({
    noteId: undefined,
    noteDate: undefined,
    note: undefined
  });
  const [isLoading, setIsLoading] = useState(false);
  

  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; 
    const day = currentDate.getDate();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();

    const date = `${month}/${day}/${year} ${hours}:${minutes}`

    setPatientNote((prev) => ({
      ...prev,
      noteId: uuidv4(),
      noteDate: date
    }));
  }, []);

  const handleNoteChanges = (event) => {
    const {value} = event.target;

    setPatientNote((prev) => ({
      ...prev,
      note: value
    }));
  };

  const handleSaveNote = async () => {
    setIsLoading(true);
    const patientToUpdate = {...patientData};
    patientToUpdate.notes = [patientNote, ...patientToUpdate.notes];
    
    const response = await updatePatient(patientToUpdate, accessToken);
    if(response.status === 201) {
      showAlert('Your note was saved!', 'Success!',  'success');
      setTimeout(() => {
        closeModal();
      }, 800);

    } else {
      showAlert(`ERROR: ${response.message}`, 'Oh, Snap!', 'danger');;
    }
  };

  const handleCancelNote = () => {
    closeModal();
  };

  if(isLoading) {
    return <div className="d-flex justify-content-center align-items-center h-100"><Loading /></div> 
  }

  return (
    <div>
      <div className="container">
        <div className="d-flex justify-content-center my-3">
          <textarea
            id={styles.newPatientNote}
            name="patientNote"
            placeholder="Jane Doe cannot be seen before 10am."
            className="mspTextArea"
            onChange={handleNoteChanges}
            rows={5}
          />
        </div>
        <div className="d-flex justify-content-end my-2">
          <button 
            disabled={!patientNote.note}
            onClick={handleSaveNote} 
            className='primaryBtn'>
              Save
          </button>
        </div>
      </div>
    </div>
  )
};

export default CreateNote;