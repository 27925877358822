import { useState, useEffect, useContext, useRef } from 'react';
import usePatientRequests from '../hooks/patient-requests';
import useDistanceRequests from '../hooks/distance-request';
import { useAlert } from '../context/alert-context';
import { UserContext } from '../context/context';
import { LocationClient, SearchPlaceIndexForSuggestionsCommand } from '@aws-sdk/client-location';
import { useSelector } from 'react-redux';

import Loading from '../pop-ups/loading';

import { Badge, CloseButton, Row, Col, Container } from 'react-bootstrap';
import styles from "./Modal_Components.module.css";

function CreatePatient({userId, accessToken, handleClose}) {
  const access_Key_Id = process.env.REACT_APP_AWS_ACCESS_KEY_ID ;
  const secret_Access_Key = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
  const { addNewPatient } = usePatientRequests();
  const { groupPatients } = useDistanceRequests();
  const user = useContext(UserContext);
  const { showAlert } = useAlert();
  const { isGroupingOn } = useSelector((state) => state.patients);
  const suggestionsRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [addMore, setAddMore] = useState(false);
  const [formData, setFormData] = useState(
    {
      firstName: '',
      lastName: '',
      coordinates: '',
      address: '',
      primaryNumber: '',
      secondaryNumber: '',
      email: '',
      noSeeDays: {sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false},
      active: true,
      frequency: 1,
      notes: []
    }
  );

  const client = new LocationClient({
    region: 'us-east-1',
    credentials: {
      accessKeyId: access_Key_Id,
      secretAccessKey:secret_Access_Key
    }
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
            setUserLocation([position.coords.longitude, position.coords.latitude]);
        },
        (error) => {
            console.error("Error getting user location:", error);
        }
      );
    } else {
      setUserLocation([user.coordinates.longitude, user.coordinates.latitude]);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
        closeSuggestions();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closeSuggestions = () => setSuggestions([]);

  const fetchSuggestions = async (input) => {
    const command = new SearchPlaceIndexForSuggestionsCommand({
        IndexName: 'MySchedulePal-PI',
        Text: input,
        MaxResults: 5,
        BiasPosition: userLocation
    });

    try {
      const response = await client.send(command);
      const suggestionTexts = response.Results.map(suggestion => suggestion.Text);
      setSuggestions(suggestionTexts);
    } catch (error) {
        console.error('Error fetching suggestions:', error);
    }
  }

  const handleAddressChange = (e) => {
    const value = e.target.value;
    setFormData(prev => ({
      ...prev,
      address: value
    }));

    if (value) {
        fetchSuggestions(value);
    } else {
        setSuggestions([]);
    }
  }

  const handleSelectAddress = (addressSelected) => {
    setFormData(prev => ({
      ...prev,
      address: addressSelected
    }));
    closeSuggestions()
  }

  const handleInputChange = (event) => {
    const {name, value} = event.target;

    setFormData({
      ...formData,
      [name]: value
    });
  }

  const handleAddMore = () => setAddMore(!addMore);

  const handleNoSeeDays = (value) => {
    setFormData((prev) => ({
      ...prev,
      noSeeDays: {
        ...prev.noSeeDays,
        [value]: !prev.noSeeDays[value]
      }
    }));
  }

  const handleSave = async () => {
    setLoading(true);
    const response = await addNewPatient(formData, userId, accessToken);
    if(isGroupingOn) {
      try {
        const storedCustomization = JSON.parse(localStorage.getItem('groupCustomization'));
        await groupPatients(user.authId, accessToken, storedCustomization.clusters);
      } catch (error) {
        console.error('Error:', error.message);
      } 
    }

    if(response.status === 201) {
      showAlert('Saved! Wow that was hard work. Go get a treat to celebrate!', 'Success',  'success');
      setTimeout(() => {
        handleClose();
      }, 800);
    } else {
      showAlert(`ERROR: ${response.message}`, 'Oh, Snap!', 'danger');
      setLoading(false);
    }
  }

  if(!loading) {
    return (
      <div className="p-1 p-md-3 d-flex flex-column justify-content-start align-items-start">
        <div className="d-flex flex-column flex-lg-row my-2">
          <div className="d-flex m-2 align-items-start justify-content-start flex-column">
            <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">*First Name</label>
            <input className="mspInput" type="text" name="firstName" value={formData.firstName} onChange={handleInputChange} placeholder="Jane" />
          </div>
          <div className="d-flex m-2 align-items-start justify-content-start flex-column">
            <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">*Last Name</label>
            <input className="mspInput" type="text" name="lastName" value={formData.lastName} onChange={handleInputChange} placeholder="Doe" />
          </div>
        </div>
        <div className="d-flex justify-content-start align-items-center my-2">
          <div className="d-flex flex-column m-2 justify-content-start align-items-start">
            <label htmlFor="first" className="mspLabel address long d-flex justify-content-start align-items-start">*Address</label>
            <input
              type="text"
              value={formData.address}
              onChange={handleAddressChange}
              className="mspInput address long"
              placeholder="Start typing address..."
            />
            <div ref={suggestionsRef} className={`${suggestions.length !== 0 ? `${styles.suggestionsContainer}` : "d-none"}`}>
              <ul>
                {suggestions.map((suggestion, index) => (
                    <li className={styles.addressItem} key={index} onClick={() => handleSelectAddress(suggestion)}>{suggestion}</li>
                ))}
              </ul>
            </div> 
          </div>
        </div>
        <div className={`${addMore ? "d-flex" : "d-none"} flex-column`}>
          <div className="d-flex flex-column flex-lg-row my-2">
            <div className="d-flex m-2 align-items-start justify-content-start flex-column">
              <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">Primary Phone</label>
              <input className="mspInput" type="tel" name="primaryNumber" value={formData.primaryNumber} onChange={handleInputChange} placeholder="123-456-7891" />
            </div>
            <div className="d-flex m-2 align-items-start justify-content-start flex-column">
              <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">Email</label>
              <input className="mspInput" type="text" name="email" value={formData.email} onChange={handleInputChange} placeholder="jane@example.com" />
            </div>
          </div>
          <div className="d-flex flex-column flex-lg-row my-2">
            <div className="d-flex m-2 align-items-start justify-content-start flex-column">
              <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">Secondary Phone</label>
              <input className="mspInput" type="tel" name="secondaryNumber" value={formData.secondaryNumber} onChange={handleInputChange} placeholder="123-456-7891" />
            </div>
            <div className="d-flex m-2 align-items-start justify-content-start flex-column">
              <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">Frequency</label>
              <div className={styles.inputWrapper}>
                <input type="number" className={styles.inputFlex} name="frequency" value={formData.frequency} onChange={handleInputChange} placeholder="2" />
                <span className={`${styles.labelFlex} px-2`}>/Week</span>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column flex-lg-row my-2">
            <div className="d-flex m-2 align-items-start justify-content-start flex-column">
              <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">Days Pt Cannot Be Seen</label>
              <select onChange={(event) => handleNoSeeDays(event.target.value)} name="noseeDays" id="noseeDays" className="mspInput">
              <option disabled defaultValue={"Select Days"} value="">Select Days</option>
                <option className={`${formData.noSeeDays.sunday ? 'd-none' : null}`} value="sunday">
                  Sunday
                </option>
                <option className={`${formData.noSeeDays.monday ? 'd-none' : null}`} value="monday">
                  Monday
                </option>
                <option className={`${formData.noSeeDays.tuesday ? 'd-none' : null}`} value="tuesday">
                  Tuesday
                </option>
                <option className={`${formData.noSeeDays.wednesday ? 'd-none' : null}`} value="wednesday">
                  Wednesday
                </option>
                <option className={`${formData.noSeeDays.thursday ? 'd-none' : null}`} value="thursday">
                  Thursday
                </option>
                <option className={`${formData.noSeeDays.friday ? 'd-none' : null}`} value="friday">
                  Friday
                </option>
                <option className={`${formData.noSeeDays.saturday ? 'd-none' : null}`} value="saturday">
                  Saturday
                </option>
              </select>
            </div>
            <div className="d-flex flex-wrap">
              {
                Object.entries(formData.noSeeDays).map(([propertyName, propertyValue]) => (
                  propertyValue && (
                  <div className='m-2' key={propertyName}>
                    <Badge className={`${styles.customBadge} d-flex justify-content-start align-items-center`} id={styles.customBadge}>
                      <CloseButton className={`${styles.xBtn} me-2`} onClick={() => handleNoSeeDays(propertyName)} /> 
                      {propertyName}
                    </Badge>
                  </div>
                )))
              }
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between w-100 mt-3">
          <button onClick={handleAddMore} className="secondaryBtn">
            {addMore ? "See Less" : "Add More Info"}
          </button>
          <button onClick={handleSave} className="primaryBtn">Save</button>
        </div>
      </div>
    );
  }

  if(loading) {
    return (
      <Container fluid className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <Loading />
          </Col>
        </Row>
      </Container>
    );
  }
  
}

export default CreatePatient;
