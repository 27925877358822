import useEnviornmentRequests from './enviornment.js';

function usePatientRequests () {
  const { getEnviornment } = useEnviornmentRequests();
  let url = getEnviornment();
  
  const returnObject = (status, message) => {
    return {
      status: status,
      message: message
    }
  };

  const getPatients = async (userId, accessToken) => {
    try {
      const response = await fetch(`${url}/patients/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      const patientData = await response.json();
     
      return patientData;
     
    } catch (error) {
        console.error('Error:', error);
        return returnObject(500, `${error.message}`);
    }
  };

  const viewPatient = async (userId, patientId, accessToken) => {
    try {
      const response = await fetch(`${url}/patient/${userId}/${patientId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      const homeData = await response.json();
      return homeData;
 
    } catch (error) {
      console.error('Error:', error);
      return returnObject(500, `${error.message}`);
    }
  };

  const updatePatient = async (patientInfo, accessToken) => {
    try {
      const options = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}`},
        body: JSON.stringify(patientInfo)
      };

      const response = await fetch(`${url}/updatePatient`, options);
      const resData = await response.json();

      return resData;

    } catch (error) {
      console.error('Error:', error);
      return returnObject(500, `${error.message}`);
    }
  };

  const addNewPatient = async (patient, userId, accessToken) => {
    try {
      const options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}`},
        body: JSON.stringify(patient)
      };

      const response = await fetch(`${url}/patients/${userId}`, options);
      const resData = await response.json();

      return resData;

    } catch (error) {
        console.log(error);
        return returnObject(500, `${error.message}`);
    }
  };

  const removePatient = async (patientId, userId, accessToken) => {
    try {
      const options = {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}`},
      };
      
      const response = await fetch(`${url}/patients/${patientId}/${userId}`, options);
      if(response.status !== 204) {
        const resData = await response.json();
        return resData;
      } else {
        return response;
      }
    } catch (error) {
        console.log(error);
        return returnObject(500, `${error.message}`);
    }
  };

  const bulkRemovePatients = async (patientsArr, userId, accessToken) => {
    try {
      const options = {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}`},
        body: JSON.stringify({patientIds: patientsArr})
      };
      
      const response = await fetch(`${url}/patients/${userId}`, options);
      if(response.status !== 204) {
        const resData = await response.json();
        return resData;
      } else {
        return response;
      }
    } catch (error) {
        console.log(error);
        return returnObject(500, `${error.message}`);
    }
  };

  return {
   getPatients,
   viewPatient,
   updatePatient,
   addNewPatient,
   removePatient,
   bulkRemovePatients
  }
}


export default usePatientRequests;
