import Modal from 'react-bootstrap/Modal';

import styles from './Modal.module.css'

const ConfirmModal = ({ title, body, handleClose, handleConfirm, show }) => {

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className={styles.floatingModalHeader} closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.floatingModalBody}>{body}</Modal.Body>
        <Modal.Footer>
          <button className='primaryBtn' onClick={handleConfirm}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmModal;