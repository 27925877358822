import React, { useContext, useState, useEffect, useRef } from 'react';

import {UserContext, AccessTokenContext} from '../../context/context';
import usePatientRequests from '../../hooks/patient-requests';;
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { selectPatientId, toggleMapExpansion } from "../../App/Features/Patient_Scheduling/Patients_Slice.js";

import Loading from '../../pop-ups/loading';

import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  CircleMarker,
  useMap
} from "react-leaflet";
import { Container } from 'react-bootstrap';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import styles from './Grouping_Map.module.css';

const getTileLayerUrl = (mapStyle) => {
  switch (mapStyle) {
    case 'OpenStreetMap':
      return {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: '&copy; OpenStreetMap contributors',
      };
    case 'MapboxStreets':
      return {
        url: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`,
        attribution: '&copy; <a href="https://www.mapbox.com/">Mapbox</a>',
      };
    case 'MapboxSatellite':
      return {
        url: `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`,
        attribution: '&copy; <a href="https://www.mapbox.com/">Mapbox</a>',
      };
    case 'CartoLight':
      return {
        url: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
        attribution: '&copy; <a href="https://www.carto.com/">CARTO</a>',
      };
    case 'CartoDark':
      return {
        url: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
        attribution: '&copy; <a href="https://www.carto.com/">CARTO</a>',
      };
    default:
      // Fallback to OpenStreetMap
      return {
        url: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`,
        attribution: '&copy; <a href="https://www.mapbox.com/">Mapbox</a>',
      };
  }
};

const GeneralMap = ({ mapStyle = 'MapboxStreets' }) => {
  const user = useContext(UserContext);
  const accessToken = useContext(AccessTokenContext);
  const { getPatients } = usePatientRequests();
  const { data: patients, refetch } = useQuery('patients', 
    () => getPatients(user.authId, accessToken)
  );
  const dispatch = useDispatch();
  const { selectedPatientId, isMapExpanded } = useSelector((state) => state.patients);

  const [leafletMap, setLeafletMap] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const customHomeIcon = L.icon({
    iconUrl: '/assets/icons/Home.svg',
    iconSize: [20,20], 
    iconAnchor: [12, 41],
    popupAnchor: [0, -41], 
  });
  const markerRefs = useRef({});
  const tileLayerConfig = getTileLayerUrl(mapStyle);

  const MapInitializer = () => {
    const map = useMap();

    useEffect(() => {
      setLeafletMap(map); // Pass the map instance to the parent state
    }, [map]);

    return null; // Doesn't render anything visually
  };

  const showMarker = () => {
    if (leafletMap && selectedPatientId !== '') {
      const selectedPatientArr = patients.message.filter((patient) => patient._id === selectedPatientId);
      const deIdentifiedCoordinates = selectedPatientArr[0].deIdentifiedCoordinates;
      leafletMap.flyTo([deIdentifiedCoordinates.latitude, deIdentifiedCoordinates.longitude], 11);
      setTimeout(() => {
        const marker = markerRefs.current[selectedPatientId];
        if (marker) {
          marker.openPopup()
        }
      }, 400);
    }
	}

  useEffect(() => {
    showMarker();
  }, [selectedPatientId]);

  const handleClickMarker = (patientId) => {
    dispatch(selectPatientId(patientId))
  }

  const handleExpand = () => {
    setIsLoading(true);
    dispatch(toggleMapExpansion());
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }

  if(patients === undefined || isLoading) {
    return (
      <Container className='d-flex justify-content-center align-items-center'>
        <Loading />
      </Container>
    );
  }

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <div style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 500 }}>
        <button
          onClick={handleExpand}
          className='secondaryBtnSmall'
          style={{boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.5)"}}
        >
          {isMapExpanded ? 'Collapse' : 'Expand'}
        </button>
      </div>
      <MapContainer className={styles.generalMap} center={[user.coordinates.latitude, user.coordinates.longitude]} zoom={9} scrollWheelZoom={false}>
      
        <MapInitializer />

        <TileLayer url={tileLayerConfig.url} attribution={tileLayerConfig.attribution} />

        {
          patients.message.map((patient, index) => (
            <CircleMarker
              key={index}
              ref={(el) => { 
                if (el) {
                  markerRefs.current[patient._id] = el;
                }
              }}
              center={[patient.deIdentifiedCoordinates.latitude, patient.deIdentifiedCoordinates.longitude]}
              pathOptions={{ color: 'var(--subtitle)',}}
              radius={6} 
              eventHandlers={{
                click: () => handleClickMarker(patient._id)
              }}
            >
              <Popup>{patient.firstName}</Popup>
            </CircleMarker>
          ))
        }
        <Marker position={[user.coordinates.latitude, user.coordinates.longitude]} icon={customHomeIcon}>
          <Popup>
            Me
          </Popup>
        </Marker>
        
      </MapContainer>
    </div>
  );
}

export default GeneralMap;