import React, { useRef } from "react";
import Slider from "react-slick";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faHeart } from '@fortawesome/free-solid-svg-icons';

function SmallCarousel() {
  let sliderRef = useRef(null);
  const heartColor = 'red';

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  return (
    <div className="slider-container">
      <Slider ref={slider => (sliderRef = slider)} {...settings}>
        <div className="d-flex justify-content-center">
          <div>Occupational Therapists <FontAwesomeIcon color={heartColor} icon={faHeart} /></div>
        </div>
        <div className="d-flex justify-content-center">
          <div>COTAs <FontAwesomeIcon color={heartColor} icon={faHeart} /></div>
        </div>
        <div className="d-flex justify-content-center">
          <div>Speech Language Pathologists <FontAwesomeIcon color={heartColor} icon={faHeart} /></div>
        </div>
        <div className="d-flex justify-content-center">
          <div>SLPAs <FontAwesomeIcon color={heartColor} icon={faHeart} /></div>
        </div>
        <div className="d-flex justify-content-center">
          <div>Physical Therapists <FontAwesomeIcon color={heartColor} icon={faHeart} /></div>
        </div>
        <div className="d-flex justify-content-center">
          <div>Nurses <FontAwesomeIcon color={heartColor} icon={faHeart} /></div>
        </div>
      </Slider>
    </div>
  );
}
export default SmallCarousel;

