import React from "react";
import styles from "./Error_Screen.module.css";

const ErrorScreen = ({ errorMessage }) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center container">
      <div className="d-flex justify-content-center align-items center"><img src="/assets/images/Scheduling.png" alt="Notes" width={300} height={300} /></div>
      <div className={`${styles.message}`}>Looks like we ran into a bit of an issue. Don't worry, we're on the case. Please refresh your screen and if the issue persists email us <span className={styles.email}>support@myschedulepal.com</span>.</div>
      <div className={`${styles.message} my-5`}>{errorMessage}</div>
    </div>
  );
}

export default ErrorScreen;