import React from "react";
import { useEffect, useState } from "react";

import styles from "./Modal_Components.module.css";

import Loading from "../pop-ups/loading";

import useComparisonRequests from "../hooks/comparison-requests";

const EventModal = ({modalProps}) => {
  const { abbrevationFix } = useComparisonRequests();

  const [patient, setPatient] = useState(undefined);
  const [event, setEvent] = useState(undefined);
  const [patientSeeDays, setPatientSeeDays] = useState(false);
  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    const clientAddress = abbrevationFix(modalProps.client.address);

    const patientData = modalProps.patients.find((patient) => {
      const patAddress = abbrevationFix(patient.address);
      return patAddress === clientAddress && `${patient.firstName} ${patient.lastName}` === modalProps.client.title;
    });

    const eventData = modalProps.myEvents.find((event) => {
      const evAddress = abbrevationFix(event.address);
      return evAddress === clientAddress && event.title === modalProps.client.title;
    });

    setPatient(patientData);
    setEvent(eventData);
    setIsloading(false);
  }, [modalProps.client, modalProps.groups, modalProps.patients]);

  useEffect(() => {
    if (patient && patient.noSeeDays) {
      const entries = Object.entries(patient.noSeeDays);
      for (let i = 0; i < entries.length; i++) {
        const [propertyName, propertyValue] = entries[i];
        if (propertyValue) {
          setPatientSeeDays(true);
          break;
        }
      }
    }
  }, [patient]);

  if(isLoading) {
    return (
      <div className="container">
        <Loading />
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row my-1">
        <div className="col">
          <div className="d-flex justify-content-end">{modalProps.client.start}</div>
        </div>
      </div>

      <div className="row my-1">
        <div className="col">
          <div className="d-flex align-items-center justify-content-start ">
            <div className={styles.title}>{modalProps.client.title}</div>
          </div>
        </div>
      </div>

      <div className={`${event.isViableDest === "bad" && event.isViableOrg === "bad" ? "row mb-1": "d-none"}`}>
        <div className="col">
          <div className="d-flex align-items-center justify-content-start ">
            <div className={styles.badAddress}>Oops! We couldn't locate this address, so testing's a no-go. Double-check and give it another shot!</div>
          </div>
        </div>
      </div>

      {patient && patientSeeDays && patient.noSeeDays ? (
        <div className="row my-3">
          <div className="col-4">
            <div className="SA-label-dark-bold">Cannot be Seen:</div>
          </div>
          <div className="col d-flex align-items-center">
            <div className="row">
                {Object.entries(patient.noSeeDays).map(([propertyName, propertyValue]) =>
                propertyValue ? (
                    <div className="col-6 d-flex me-1 badge" id="customBadge" key={propertyName}>
                    {propertyName}
                    </div>
                ) : null
                )}
            </div>
          </div>
        </div>
      ) : null}

      {patient ? (
        <div>
          <div className={`${patient.primaryNumber === "" ? "d-none" : "d-flex"} row my-3`}>
            <div className="col-4">
              <div className={styles.label}>Primary Number:</div>
            </div>
            <div className="col">
              <div className={styles.infoDiv}>{patient.primaryNumber || 'NA'}</div>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-4">
              <div className={styles.label}>Frequency:</div>
            </div>
            <div className="col">
              <div className={styles.infoDiv}>{patient.frequency}/week</div>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-4">
              <div className={styles.label}>Address:</div>
            </div>
            <div className="col">
              <div className={`${event.isViableDest === "bad" && event.isViableOrg === "bad" ? `${styles.badAddress}`: `${styles.infoDiv}`}`}>{patient.address}</div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default EventModal;
