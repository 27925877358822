import React, { useRef } from 'react';
import { useState } from "react";
import { useAlert } from '../context/alert-context';
import emailjs from '@emailjs/browser';

import LoadingModal from '../static/Modals/Loading_Modal';

const Feedback = ({handleClose, user}) => {
  const form = useRef();
  const { showAlert } = useAlert();
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    user_name: `${user.firstName} ${user.lastName}`,
    user_email: user.email,
    user_comment: '',
  });

  const handleInputChange = (event) => {
    const {name, value} = event.target;

    setFormData({
      ...formData,
      [name]: value
    });
  }

  const sendEmail = (e) => {
    e.preventDefault();
    setShowModal(true);

    emailjs.sendForm('service_7h6l1j2', 'template_udwfu8r', form.current, 'wLYZCwyNGuTe-MHOZ')
      .then((result) => {
        showAlert(`Thank you so much for your feedback. We'll respond accordingly.`, 'Feedback Submitted', 'success');
        setShowModal(false);
        handleClose();

      }, (error) => {
          showAlert(`Whoops, please try again.`, 'Oh, Snap!', 'danger');
          console.log(error.text);
      });
  };

  return (
    <div className="d-flex flex-column">
      <form ref={form} onSubmit={sendEmail}>
        <div className="d-flex m-2 align-items-start justify-content-start flex-column">
          <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">*Name</label>
          <input className="mspInput" type="text" name="user_name" value={formData.user_name}  onChange={(event) => handleInputChange(event)} placeholder="Jane" />
        </div>
        <div className="d-flex m-2 align-items-start justify-content-start flex-column">
          <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">*Email</label>
          <input className="mspInput" type="text" name="user_email" value={formData.user_email}  onChange={(event) => handleInputChange(event)} placeholder="email@example.com" />
        </div>
        <textarea
          as="textarea"
          rows={5}
          type="text"
          className="mspTextArea m-2"
          placeholder='Issues, feature requests and ideas.'
          id="user_comment"
          name="user_comment"
          value={formData.user_comment}
          onChange={(event) => handleInputChange(event)}
        />
        <div className="d-flex justify-content-end mt-3">
          <button className="primaryBtn" disabled={!formData.user_comment || !formData.user_email || !formData.user_name} type="submit">
            Submit
          </button>
        </div>
      </form>
      <LoadingModal handleClose={() => setShowModal(false)} show={showModal}/>
    </div>
  );
}

export default Feedback;