import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import usePatientRequests from '../../hooks/patient-requests';
import { useAlert } from '../../context/alert-context.js';
import {UserContext, AccessTokenContext} from '../../context/context';
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from 'react-redux';
import { fetchPatients } from "../../App/Features/Patient_Scheduling/Patients_Thunks.js";

import Loading from "../../pop-ups/loading";
import ErrorScreen from '../../static/Error/Error_Screen.js';
import FloatingModal from '../../static/Modals/Floating_Modal.js';
import EditPatient from '../../Modal_Components/Edit_Patient';
import CreateNote from '../../Modal_Components/Create_Note';
import EditNote from '../../Modal_Components/Edit_Note';
import ConfirmModal from '../../static/Modals/Confirm_Modal.js';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import { Badge, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faEllipsis, faPen, faFolderMinus } from '@fortawesome/free-solid-svg-icons';
import styles from "./Patient_Info.module.css";

const PatientInfo = ({isOpen}) => {  
  const user = useContext(UserContext);
  const accessToken = useContext(AccessTokenContext);
  const dispatch = useDispatch();
  const { isLoading } = useAuth0();

  const {viewPatient, updatePatient, removePatient} = usePatientRequests();
	const { showAlert } = useAlert();
  const { id } = useParams();
  const { data: patient, status, refetch } = useQuery('patient',
    () => viewPatient(user.authId, id, accessToken)
  );

  const [patientData, setPatientData] = useState(undefined);
  const [hasError, setHasError] = useState(false);
  const [hoveredId, setHoveredId] = useState(null);
  const [showEditPatient, setShowEditPatient] = useState(false);
  const [showBottomNote, setShowBottomNote] = useState(false);
  const [showBottomEditNote, setShowBottomEditNote] = useState(false);
	const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [noteToEdit, setNoteToEdit] = useState({editId: '', patientData: '' });

  useEffect(() => {
    if(patient) {
      setPatientData(patient.message);
    } 
  },[patient]);

  useEffect(() => {
    handleRefetch();
  }, [isOpen]);

  const handleEditInfo = () => setShowEditPatient(true);
  const handleNote = () => setShowBottomNote(true);
  const showEditNote = () => setShowBottomEditNote(true);

  const handleClose = () => {
    setShowEditPatient(false);
    setShowBottomNote(false);
    setShowBottomEditNote(false);
		setShowArchiveModal(false);
    handleRefetch();
  }
  
  const handleDeletePatientModal = () => {
    const deleteProps = {
      patientData: patientData,
      patientId: id,
    }
		setShowArchiveModal(true);
  }

	const handleArchivePatient = async () => {
		const response = await removePatient(patientData._id, user.authId, accessToken);
		if(response.status !== 204) {
      showAlert(`ERROR: ${response.message}`, 'Oh, Snap!', 'danger');
    } else {
			showAlert(`Your Patient was successfully archived.`, 'Success!', 'success');
			window.location.href = "/patients";
		}
	}

  const handleEditNote = (noteId) => {
    const editProps = {
      patientData: patientData,
      editId: noteId
    }
    setNoteToEdit(editProps);

    showEditNote();
  }

  const handleActive = () => {
    setPatientData((prev) => {
      const updatedData = { ...prev, active: !prev.active };
      
      setTimeout(() => {
        handleSave(updatedData);
      }, 500);
  
      return updatedData;
    });

  }

  const handleSave = async (data) => {
    const response = await updatePatient(data, accessToken);
    if(response.status !== 201) {
      showAlert(`ERROR: ${response.message}`, 'Oh, Snap!', 'danger');
    } else {
      dispatch(fetchPatients({ user, accessToken }));
    }
  }

  const handleNoteRemoval = (noteId) => {
    setPatientData((prevData) => ({
      ...prevData,
      notes: prevData.notes.filter((note) => note.noteId !== noteId)
    }));
  }

  const handleRefetch = async () => {
    const { data } = await refetch();
    if(data.status !== 200) {
      setHasError(true);
    }
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      This change cannot be undone.
    </Tooltip>
  );

  if(isLoading) {
    return <div><Loading /></div>
  }

  if(!patientData) {
    return <div><Loading /></div>
  }

  if(hasError) {
    return (
      <ErrorScreen errorMessage={""} />
    );
  }

  return (
    <div className="p-3 p-md-5">
      <div className={styles.overallContainer}>
        <div className={styles.infoContainer}>
          <div className={styles.basicInfo}>
            <div className={styles.header}>
              <div className={styles.title}>{patientData.firstName} {patientData.lastName}</div>
              <Dropdown className={styles.dropdown}>
								<Dropdown.Toggle variant="none" id="dropdown-basic" className={`${styles.dropdownToggle} d-flex align-items-center`}>
									<FontAwesomeIcon color={'var(--mainBg)'} icon={faEllipsis} style={{ fontSize: '25px' }} />
								</Dropdown.Toggle>
								<Dropdown.Menu className={styles.dropdownMenu}>
									<Dropdown.Item className={`${styles.dropdownItem} d-flex align-items-center justify-content-between`} onClick={handleEditInfo}>Edit <FontAwesomeIcon color={'var(--primary)'} icon={faPen} style={{ fontSize: '15px' }} /></Dropdown.Item>
									<Dropdown.Item className={`${styles.dropdownItem} d-flex align-items-center justify-content-between`}onClick={handleDeletePatientModal}>Archive Patient <FontAwesomeIcon color={'var(--primary)'} icon={faFolderMinus} style={{ fontSize: '15px' }} /></Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
            </div>
            <div className="p-3 d-flex flex-column w-100">
              <div className={`${patientData.primaryNumber ? "d-flex" : "d-none"} mb-3 d-flex align-items-center justify-content-between`}>
                <img src="/assets/icons/Phone.svg" alt="Phone" width={16} height={16} />
                <div className={styles.infoDiv}>{patientData.primaryNumber}</div>
              </div>
              <div className={`${patientData.secondaryNumber ? "d-flex" : "d-none"} mb-3 d-flex align-items-center justify-content-between`}>
                <img src="/assets/icons/Phone.svg" alt="Phone" width={16} height={16} />
                <div className={styles.infoDiv}>{patientData.secondaryNumber}</div>
              </div>
              <div className={`${patientData.email ? "d-flex" : "d-none"} mb-3 d-flex align-items-center justify-content-between`}>
                <img src="/assets/icons/Email.svg" alt="Phone" width={16} height={16} />
                <div className={styles.infoDiv}>{patientData.email}</div>
              </div>
              <div className="mb-3 d-flex align-items-center justify-content-between">
                <img src="/assets/icons/Address.svg" alt="Phone" width={16} height={16} />
                <div className={styles.infoDiv}>{patientData.address}</div>
              </div>
              <div className={`${patientData.frequency ? "d-flex" : "d-none"} d-flex align-items-center justify-content-between`}>
                <img src="/assets/icons/Frequency.svg" alt="Phone" width={16} height={16} />
                <div className={styles.infoDiv}>{patientData.frequency}/Week</div>
              </div>
            </div>
          </div>
          <div className={styles.noSeeDaysInfo}>
            <div className={styles.header}>
              <div className={styles.title}>Cannot Be Seen</div>
            </div>
            <div className={`${Object.values(patientData.noSeeDays).some(value => value === true) ? "d-flex" : "d-none"} p-3 p-md-5 d-flex flex-wrap`}>
              {
                Object.entries(patientData.noSeeDays).map(([propertyName, propertyValue]) => (
                  propertyValue && (
                  <div className='m-2' key={propertyName}>
                    <Badge className={`${styles.customBadge} d-flex justify-content-start align-items-center`} id={styles.customBadge}>
                      {propertyName}
                    </Badge>
                  </div>
                )))
              }
            </div>
            <div className={`${Object.values(patientData.noSeeDays).every(value => value === false) ? "d-flex" : "d-none"} d-flex flex-wrap p-3 p-md-5 text-center`}>
              <div className={`${styles.noSeeExp}`}>Click the 3 dots next to the patient name to add any days your patient cannot be seen.</div>
            </div>
          </div>
        </div>
        <div className={styles.notesInfo}>
          <div className={styles.header}>
            <div className={styles.title}>Scheduling Notes</div>
            <button onClick={handleNote} className="transparentBtnPrimary">Add</button>
          </div>
          <div className={`${patientData.notes.length === 0 ? "d-flex" : "d-none"} d-flex flex-column justify-content-center align-items center p-3 p-md-5`}>
            <div className="d-flex justify-content-center align-items center"><img src="/assets/images/Scheduling.png" alt="Notes" width={200} height={200} /></div>
            <div className={`${styles.noSeeExp} ${styles.infoDiv}`}>Quickly jot down notes about the patient’s schedule, parking, or anything important to remember. Click "Add" above to begin!</div>
          </div>
          <div className={`${styles.notesContainer} p-3`}>
            {patientData.notes.map((pNote) => (
              <div key={pNote.noteId} className={`${styles.noteContainer} ${hoveredId === pNote.noteId ? styles.highlightContainer : ''} p-2 my-4`}>
                <Col className="note-cont">
                  <Row>
                    <Col className='d-flex justify-content-end'>
                      <div className={styles.noteTime}>{pNote.noteDate}</div>
                    </Col>
                  </Row>

                  <Row className="py-2">
                    <Col>
                      <div className={styles.noteContent}>{pNote.note}</div>
                    </Col>
                  </Row> 

                  <Row className="note-bottom">
                    <Col className='d-flex justify-content-end'>
                      <div className="d-flex">
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip}
                        >
                          <button 
                            onClick={() => handleNoteRemoval(pNote.noteId)} 
                            onMouseEnter={() => setHoveredId(pNote.noteId)}
                            onMouseLeave={() => setHoveredId(null)} 
                            className="me-2 transparentBtnSecondary">
                              Delete
                          </button>
                        </OverlayTrigger>
                        <button
                          onMouseEnter={() => setHoveredId(pNote.noteId)}
                          onMouseLeave={() => setHoveredId(null)} 
                          onClick={() => handleEditNote(pNote.noteId)} 
                          className="transparentBtnSecondary">
                            Edit
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div>
            ))}
          </div>
        </div>
      </div>
      <FloatingModal 
        title="Patient Notes" 
        component={<CreateNote patientData={patientData} accessToken={accessToken} userId={user.authId} closeModal={handleClose} />} 
        show={showBottomNote} 
        handleClose={handleClose}
      />
      <FloatingModal 
        title="Patient Info" 
        component={<EditPatient patient={patientData} accessToken={accessToken} userId={user.authId} handleClose={handleClose} />} 
        show={showEditPatient} 
        handleClose={handleClose}
      />
      <ConfirmModal
				title="Archive Patient?"
				body="This action cannot be undone."
				show={showArchiveModal}
				handleConfirm={handleArchivePatient}
				handleClose={handleClose}
			/>
      <FloatingModal 
        title="Edit Your Note" 
        component={<EditNote modalProps={noteToEdit} accessToken={accessToken} closeModal={handleClose} />} 
        show={showBottomEditNote} 
        handleClose={handleClose}
      />
    </div>
  );
}

export default PatientInfo;
 