
function useEnviornmentRequest () {
  const getEnviornment = () => {
    if(process.env.REACT_APP_CURRENT_ENV === "local") {
      return process.env.REACT_APP_API_URL_LOCAL;
    } else {
      return process.env.REACT_APP_API_URL;
    }
  }
  return {
    getEnviornment
  }
};
  
export default useEnviornmentRequest;