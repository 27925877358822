import useEnviornmentRequests from './enviornment.js';

function useUserRequests () {
  const { getEnviornment } = useEnviornmentRequests();
  let url = getEnviornment();
  
  const returnObject = (status, message) => {
    return {
      status: status,
      message: message
    }
  };

  const getUser = async (userId, accessToken) => {
    try {
      const response = await fetch(`${url}/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      const userData = await response.json();

      return userData;
      
    } catch (error) {
        console.error('Error:', error);
        return returnObject(500, `"${error.message}." \nLooks like there was an internal error or the right information was not submitted please try again.`);
    }
  }

  const addUser = async (newUser, accessToken) => {
    console.log(accessToken);
    try {
      const options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}`},
        body: JSON.stringify(newUser)
      };

      console.log("BEFORE SEND", newUser);

      const response = await fetch(`${url}/user`, options);
      console.log("AFTER SEND", response);
      const userAdded = await response.json();

      return userAdded;
    } catch (error) {
      console.error('Error:', error);
      return returnObject(500, `"${error.message}." \nLooks like there was an internal error or the right information was not submitted please try again.`);
    }
  }

  const getOnboardingData = async (userId, accessToken) => {
    try {
      const response = await fetch(`${url}/onboardingData/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      const onboardingData = await response.json();

      return onboardingData.message;
      
    } catch (error) {
        console.error('Error:', error);
        return returnObject(500, `"${error.message}." \nLooks like there was an internal error or the right information was not submitted please try again.`);
    }
  }

  const updateUser = async (userId, accessToken, userInfo) => {
    try {
      const options = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}`},
        body: JSON.stringify(userInfo)
      };

      const response = await fetch(`${url}/user/${userId}`, options);

      const userUpdated = await response.json();

      return userUpdated;
    } catch (error) {
      return returnObject(500, error);
    }
  }

  return {
    getUser,
    addUser,
    getOnboardingData,
    updateUser
  };
}

export default useUserRequests;


