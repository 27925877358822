import React from "react";

import ErrorModal from "../pop-ups/error-modal";
import Loading from "../pop-ups/loading";
import LoadingModal from "../pop-ups/loading-modal";
import InitiateGroupingModal from "../pop-ups/initiate-grouping-modal";
import PatientGroupModal from "../pop-ups/patient-group-modal";

import "../css/modal-wrapper.css";

const ModalWrapper = (props) => {
  const MODAL_COMPONENTS = {
    ErrorModal: ErrorModal,
    Loading: Loading,
    LoadingModal: LoadingModal,
    InitiateGroupingModal: InitiateGroupingModal,
    PatientGroupModal: PatientGroupModal,
  };

  const SelectedModal = MODAL_COMPONENTS[props.modalType] || ErrorModal;

  const isSmallerModal = () => {
    if (SelectedModal === ErrorModal || SelectedModal === InitiateGroupingModal || SelectedModal === PatientGroupModal) {
      return true;
    } else {
      return false;
    }
  };
 
  return (
    <div className={`modal-wrapper ${isSmallerModal() ? 'smaller-modal-wrapper' : ''}`}>
      <SelectedModal userId={props.userId} accessToken={props.accessToken} modalProps={props.modalProps} closeModal={props.closeModal}/>
    </div>
    
  )
};

export default ModalWrapper;