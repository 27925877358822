import React from "react";
import { useState, useRef, useEffect } from "react";
import { useTrail, animated } from 'react-spring';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import ConfirmModal from "../../static/Modals/Confirm_Modal";

import 'swiper/css';
import 'swiper/css/pagination';
import './Onboarding.css';
import styles from "./Onboarding.module.css";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { LocationClient, SearchPlaceIndexForSuggestionsCommand } from '@aws-sdk/client-location';
import useUserRequests from "../../hooks/user-requests";
import LoadingModal from "../../static/Modals/Loading_Modal";
import TosModal from "../../static/Modals/Tos_Modal";

const Onboarding = ({authId, email, accessToken}) => {
  const access_Key_Id = process.env.REACT_APP_AWS_ACCESS_KEY_ID ;
  const secret_Access_Key = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
  const { addUser } = useUserRequests();

  const swiperRef = useRef(null);
  const suggestionsRef = useRef(null);
  const [suggestions, setSuggestions] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState({
    authId: authId,
    role: ['USER'],
    email: email,
    primaryPhone: '',
    coordinates: '',
    address: '',
    firstName: '',
    lastName: '',
    designation: '',
    sessionLength: 50,  
    bufferTime: 5,
    maxVisits: 6,  
    workingDays: 5,  
    startTime: '08:00',
    lastLogin: '', 
    hasOnboarded: false,
    tosVersion: "",
    privacyPolicyVersion: ""
  }); 
  const [showTOSModal, setShowTOSModal] = useState(false);

  const closeSuggestions = () => setSuggestions([]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
        closeSuggestions();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  const client = new LocationClient({
    region: 'us-east-1',
    credentials: {
      accessKeyId: access_Key_Id,
      secretAccessKey:secret_Access_Key
    }
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
            setUserLocation([position.coords.longitude, position.coords.latitude]);
        },
        (error) => {
            console.error("Error getting user location:", error);
        }
      );
    } else {
      console.log("user location unseen.")
    }
  }, []);

  const fetchSuggestions = async (input) => {
    const command = new SearchPlaceIndexForSuggestionsCommand({
        IndexName: 'MySchedulePal-PI',
        Text: input,
        MaxResults: 5,
        BiasPosition: userLocation
    });

    try {
      const response = await client.send(command);
      const suggestionTexts = response.Results.map(suggestion => suggestion.Text);
      setSuggestions(suggestionTexts);
    } catch (error) {
        console.error('Error fetching suggestions:', error);
    }
  }

  const handleAddressChange = (e) => {
    const value = e.target.value;
    setUserData(prev => ({
      ...prev,
      address: value
    }));

    if (value) {
      fetchSuggestions(value);
    } else {
      setSuggestions([]);
    }
  }

  const handleSelectAddress = (addressSelected) => {
    setUserData(prev => ({
      ...prev,
      address: addressSelected
    }));

    setSuggestions([]);
  }

  useEffect(() => {
    const now = new Date();
    setUserData(prev => ({
      ...prev,
      lastLogin: now
    }))
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUserData(prev => ({
        ...prev,
        [name]: value
    }));
  }

  const handleDesignation = (name) => {
    setUserData(prev => ({
      ...prev,
      designation: name
  }));
  }

  const handleTosAndPrivacy = () => setShowTOSModal(true);

  const agreeTosAndPrivacy = () => {
    setUserData(prev => ({
      ...prev,
      tosVersion: "V.1.0",
      privacyPolicyVersion: "V.1.0"
    }));

    const updatedUserData = {
      ...userData,
      tosVersion: "V.1.0",
      privacyPolicyVersion: "V.1.0",
    };

    submitUser(updatedUserData);
  }

  const submitUser = async (updatedUserData) => {
    try {
      setIsLoading(true);
      const response = await addUser(updatedUserData, accessToken);

      if(response.error) {
        setShowTOSModal(false);
        handleErrorModal();
        console.log(`An Unexpected Error Occured: ${error.message}`);
        setIsLoading(false);
      } else if(response.status === 201) {
        setShowTOSModal(false);
        window.location.pathname = '/';
      } else if(response.status === 400) {
        setShowTOSModal(false);
        handleErrorModal();
        setIsLoading(false);
        console.log('bad req');
      } else {
        setShowTOSModal(false);
        handleErrorModal();
        setIsLoading(false);
        console.log(`An Unexpected Error Occured: ${error.message}`);
      }
      
    } catch (error) {
      handleErrorModal();
      setIsLoading(false);
      console.log(`An Unexpected Error Occured: ${error}`);
    }
  }

  const handleNextSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext(); 
    }
  }

  const handlePrevSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev(); 
    }
  };

  const welcome = () => {
    const items = [
      <h1 className="titlePrimary pb-2">Welcome to MySchedulePal</h1>,
      <h4 className="subtitle pb-4">Let's talk about you</h4>,
      <button className="primaryBtn" onClick={handleNextSlide}>Let's Go!</button>,
    ];

    const trail = useTrail(items.length, {
      from: { opacity: 0, transform: 'translateY(50px) scale(0.9)' },
      to: { opacity: 1, transform: 'translateY(0px) scale(1)' },
      config: { mass: 1, tension: 500, friction: 50 },
    });

    return (
      <div className={styles.welcomeContainer}>
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ flex: 1 }}
        >
          {trail.map((animation, index) => (
            <animated.div key={index} style={animation}>
              {items[index]}
            </animated.div>
          ))}
        </div>
      </div>
    );
  }

  const name = () => {
    return (
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-center align-items-center mb-4">
          <h4 className="subtitlePrimary">What's Your Name?</h4>
        </div>
        <div className="d-flex align-items-center justify-content-start flex-column mb-4">
          <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">First</label>
          <input className="mspInput mb-4" type="text" name="firstName" value={userData.firstName} onChange={handleChange} placeholder="Jane" />
          <label htmlFor="first" className="mspLabel d-flex justify-content-start align-items-start">Last</label>
          <input className="mspInput" type="text" name="lastName" value={userData.lastName} onChange={handleChange} placeholder="Doe" />
        </div>
        <div className="d-flex justify-content-end align-items-end pt-4">
          <button className="primaryBtn" disabled={!userData.firstName || !userData.lastName} onClick={handleNextSlide}>Next</button>
        </div>
      </div>
    );
  }

  const designation = () => {
    const box = (name) => {
      return (
        <div onClick={() => handleDesignation(name)} className={`${styles.designationBox} ${userData.designation == name ? `${styles.active}` : ''} m-2 container d-flex justify-content-center align-items-center`}>
          <div className={styles.designationName}>{name}</div>
        </div>
      );
    }
    return (
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-center align-items-center mb-4">
          <h4 className="subtitlePrimary">Your Role</h4>
        </div>
        <div className={`${styles.boxContainer}`}>
          {box('OT')}
          {box('COTA')}
          {box('SLP')}
          {box('SLPA')}
          {box('PT')}
          {box('PTA')}
          {box('RN')}
          {box('CNA')}
          {box('Other')}
        </div>
        <div className="d-flex justify-content-end align-items-end pt-4">
          <button className="primaryBtn" disabled={!userData.designation} onClick={handleNextSlide}>Next</button>
        </div>
      </div>
    );
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Account for unexpected delays: traffic, hard to find addresses or just a second to breathe and prepare.
    </Tooltip>
  );

  const schedulePreferences = () => {
    return (
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-center align-items-center mb-4">
          <h4 className="subtitlePrimary">Scheduling Preferences</h4>
        </div>
        <div className="d-flex align-items-center justify-content-center flex-column mb-lg-3" style={{'position': 'relative'}}>
          <label htmlFor="first" className="mspLabel address d-flex justify-content-start align-items-start">Starting Location (Your Home Address)</label>
          <input
            className="mspInput address mb-2"
            type="text"
            value={userData.address}
            onChange={handleAddressChange}
            placeholder="Start typing address..."
          />
          <div ref={suggestionsRef} className={`${suggestions.length !== 0 ? `${styles.suggestionsContainer}` : "d-none"}`}>
            <ul>
              {suggestions.map((suggestion, index) => (
                  <li className={styles.addressItem} key={index} onClick={() => handleSelectAddress(suggestion)}>{suggestion}</li>
              ))}
            </ul>
          </div>       
        </div>
        <div className={`${styles.doubleInputContainer} mb-3`}>
          <div className="d-flex m-2 align-items-center justify-content-start flex-column">
            <label htmlFor="first" className="mspLabel small d-flex justify-content-start align-items-start">Phone Number</label>
            <input className="mspInput small" type="number" name="primaryPhone" value={userData.primaryPhone} onChange={handleChange} placeholder="480-123-4567" />
          </div>
          <div className="d-flex m-2 align-items-center justify-content-start flex-column">
            <label htmlFor="first" className="mspLabel small d-flex justify-content-start align-items-start">Typical Start Time</label>
            <input className="mspInput small" type="time" name="startTime" value={userData.startTime} onChange={handleChange} />
          </div>
        </div>
        <div className={`${styles.doubleInputContainer} mb-3`}>
          <div className="d-flex m-2 align-items-center justify-content-start flex-column">
            <label htmlFor="first" className="mspLabel small d-flex justify-content-start align-items-start">Session Length</label>
            <div className={styles.inputWrapper}>
              <input type="number" className={styles.inputFlex} name="sessionLength" value={userData.sessionLength} onChange={handleChange} placeholder="50" />
              <span className={`${styles.labelFlex} px-2`}>min</span>
            </div>
          </div>
          <div className="d-flex m-2 align-items-center justify-content-start flex-column">
            <label htmlFor="first" className="mspLabel small d-flex justify-content-start align-items-start">
              Buffer Time 
              <span className="d-flex align-items-center justify-content-center">
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <img src="assets/icons/Info.svg" alt="info" style={{ width: '15px', height: '15px' }} />
                </OverlayTrigger>
              </span>
            </label>
            <div className={styles.inputWrapper}>
              <input type="number" className={styles.inputFlex} name="bufferTime" value={userData.bufferTime} onChange={handleChange} placeholder="5" />
              <span className={`${styles.labelFlex} px-2`}>min</span>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end align-items-end pt-4">
          <button className="primaryBtn" disabled={!userData.address || !userData.primaryPhone || !userData.startTime || !userData.sessionLength || !userData.bufferTime} onClick={handleTosAndPrivacy}>Save</button>
        </div>
      </div>
    );
  }

  const handleErrorModal = () => setShowModal(!showModal);

  return (
    <>
    <div className={styles.backBtnContainer}><img  onClick={handlePrevSlide} className={styles.backBtn} src="/assets/icons/Back_Button.svg" alt="Back" width="25" height="25" /></div>
      <Swiper
        ref={swiperRef}
        pagination={{ clickable: false }}
        allowTouchMove={false}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>{welcome()}</SwiperSlide>
        <SwiperSlide>{name()}</SwiperSlide>
        <SwiperSlide>{designation()}</SwiperSlide>
        <SwiperSlide>{schedulePreferences()}</SwiperSlide>
      </Swiper>
      <LoadingModal handleClose={handleErrorModal} show={isLoading}/>
      <ConfirmModal title={"Oops!"} body={"Uh-oh, something went sideways! If this keeps happening, don't worry—you can give us a shout at support@myschedulepal.com, and we'll work our magic."} handleClose={handleErrorModal} handleConfirm={handleErrorModal} show={showModal} />
      <TosModal handleClose={handleErrorModal} handleConfirm={agreeTosAndPrivacy} show={showTOSModal} />
    </>
  );

}

export default Onboarding;

   