import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "react-big-calendar/lib/css/react-big-calendar.css"
import { AlertProvider } from './context/alert-context';
import store from './App/store';
import { Provider } from 'react-redux'

const queryClient = new QueryClient();
const enviornment = 'local';

const root = ReactDOM.createRoot(document.getElementById('root'));
const getAuthParams = () => {
  if(process.env.REACT_APP_CURRENT_ENV === 'local') {
    return {
      redirect_uri: window.location.origin,
      audience: "https://myschedulepal-local-api.com",
      scope: "openid profile email read:user write:user"
    }
  } else {
    return {
      redirect_uri: window.location.origin,
      audience: "https://myschedulepal-api.com",
      scope: "openid profile email read:user write:user"
    }
  }
}
const getClientId = () => {
  if(process.env.REACT_APP_CURRENT_ENV === 'local') {
    return "Vj4JrwJkaPcjDPHroajv6cpneLeyRSz1"
  } else {
    return "msIJUVjvBfRU9zyGCG0fKpfzIbCCzKXH"
  }
}
root.render(
    <Auth0Provider
        domain="dev-j10lgn4lktvddu6e.us.auth0.com"
        clientId={getClientId()}
        authorizationParams={getAuthParams()} 
    >
        <QueryClientProvider client={queryClient}>
          <AlertProvider>
            <Provider store={store}>
              <App />
            </Provider>
          </AlertProvider>
        </QueryClientProvider>
    </Auth0Provider>,
);


reportWebVitals();
