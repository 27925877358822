import React, { useContext, useEffect, useState } from 'react';

import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import {UserContext, AccessTokenContext} from '../../context/context';
import usePatientRequests from '../../hooks/patient-requests';
import { useAlert } from '../../context/alert-context';
import { useDispatch } from 'react-redux';
import { fetchPatients } from "../../App/Features/Patient_Scheduling/Patients_Thunks.js";

import Loading from '../../pop-ups/loading';
import CreatePatient from '../../Modal_Components/Create_Patient';
import ErrorScreen from '../../static/Error/Error_Screen';
import FloatingModal from '../../static/Modals/Floating_Modal';
import LoadingModal from '../../static/Modals/Loading_Modal';

import { Container } from 'react-bootstrap';
import styles from "./Patient_List.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

function PatientList({openModal, isOpen}) {
  const { isLoading } = useAuth0();
  const user = useContext(UserContext);
  const accessToken = useContext(AccessTokenContext);
  const dispatch = useDispatch();
  const { showAlert } = useAlert();

  const { getPatients, bulkRemovePatients } = usePatientRequests();
  const { data: patients, refetch } = useQuery('patients', 
    () => getPatients(user.authId, accessToken)
  );

  const [filteredPatients, setFilteredPatients] = useState([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [hasError, setHasError] = useState(false);
  const [show, setShow] = useState(false);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    handleRefetch();
  }, [isOpen]);

  useEffect(() => {
    if (patients !== undefined && Array.isArray(patients.message)) {
      setFilteredPatients(patients.message.reverse());
    }
  }, [patients]);

  const handleClose = () => {
    setShow(false);
    handleRefetch();
  }
  const handleShowAddPatient = () => setShow(true);

  const handleSearchName = (event) => { 
    setSearchFilter(event);
  }

  const handleNoFilter = () => {
    setSearchFilter("");
    setFilteredPatients(patients.message.reverse());
  }

  const searchName = () => {
    if (!searchFilter.trim()) {
      window.alert("Please enter a name to search.");
      return;
    }
  
    const searchInput = searchFilter.toUpperCase().split(" "); 
  
    const matchedPatients = patients.message.filter((patient) => {
      const fullName = `${patient.firstName.toUpperCase()} ${patient.lastName.toUpperCase()}`; 

      return searchInput.every((term) => fullName.includes(term));
    });
  
    if (matchedPatients.length > 0) {
      setFilteredPatients(matchedPatients); 
    } else {
      window.alert(`Sorry, it looks like there is no one by the name of "${searchFilter}" in your patient list.`);
    }
  }

  const handleRefetch = async () => {
    const { data, error, failureCount, isFetching } = await refetch();
    if(data.error || data.status !== 200) {
      setHasError(true);
    } else if(data) {
      setHasError(false);
    }
  }

  const handleCheckPatient = (id) => {
    setChecked((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };
  

  const handleIsChecked = (id) => {
    return checked.includes(id); 
  };

  const handleRemovePatients = async () => {
    setIsLoadingModal(true);
    const response = await bulkRemovePatients(checked, user.authId, accessToken);
    if(response.status === 204) {
      await handleRefetch();
      dispatch(fetchPatients({ user, accessToken }));
    } else {
      showAlert(
        "We're unable to remove some or all of your patients. Please try again.", 
        "Oh, Snap", 'danger'
      );
    }

    setTimeout(() => {
      setIsLoadingModal(false);
      setChecked([]);
    }, 750);
    
  }

  const closeIsLoadingModal = () => setIsLoadingModal(false);

  if(isLoading) {
    return (
      <Container className='d-flex justify-content-center align-items-center'>
        <Loading />
      </Container>
    );
  }

  if(hasError) {
    return (
      <ErrorScreen errorMessage={""} />
    );
  }

  return (
    <div className="p-5">
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center justify-content-between">
          <div className={`${styles.searchContainer} d-flex align-items-center`}>
            <div className={styles.inputWrapper}>
              <span className={`${styles.labelFlex} px-2`}>
                <FontAwesomeIcon icon={faMagnifyingGlass} color="var(--primary)" />
              </span>
              <input
                onChange={(e) => handleSearchName(e.target.value)}
                value={searchFilter}
                type="text"
                className={styles.inputFlex}
                name="search"
                placeholder="Search by name..."
              />
            </div>
            <button onClick={searchName} className="secondaryBtn ms-3">Search</button>
          </div>
          <div className={styles.totalPatients}>Total Patients: {filteredPatients.length}</div>
        </div>
        <div className="d-flex align-items-center justify-content-between mt-4">
          <div className="subtitlePrimary d-flex align-items-center">Patients <button onClick={handleNoFilter} className={`${searchFilter !== "" ? "d-inline" : "d-none"} secondaryBtn ms-3`}>View All</button></div>
          <button onClick={handleShowAddPatient} className="primaryBtn">Add</button>
        </div>
        <div className="d-flex flex-column align-items-start justify-content-start mt-4">
          <button onClick={handleRemovePatients} 
            disabled={checked.length < 1} 
            className='transparentBtnTertiary d-flex align-items-center'>
              <img width={15} height={15} 
                src={checked.length > 0 ? "/assets/icons/Trash.svg" : "/assets/icons/Trash_Disabled.svg"} 
                alt="delete" 
              /> 
              Remove Patients
          </button>
          <div className={styles.patientsContainer}>
            <div className={styles.patientsContainerHeader}>
              <div className={styles.infoDiv}>Name</div>
              <div className={styles.numberDiv}>Number</div>
              <div className={styles.infoDiv}>Address</div>
            </div>
            <div className={styles.patientSection}>
              {filteredPatients.map((patient, index) => (
                <div className="d-flex align-items-center w-100">
                  <div className="d-flex align-items-center flex-grow-1">
                    <input 
                      type="checkbox" 
                      className="mx-2 d-flex" 
                      onChange={() => handleCheckPatient(patient._id)}
                      checked={handleIsChecked(patient._id)}
                    />
                    <Link
                      to={`/patients/${patient._id}`}
                      style={{ textDecoration: "none", color: "inherit", flexGrow: 1 }}
                      className={styles.patient}
                    >
                      <div className={styles.infoDiv}>
                        {patient.firstName} {patient.lastName}
                      </div>
                      <div className={styles.numberDiv}>{patient.primaryNumber || "--"}</div>
                      <div className={styles.infoDiv}>{patient.address}</div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <FloatingModal 
        title="New Patient" 
        component={<CreatePatient userId={user.authId} accessToken={accessToken} handleClose={() => handleClose()}  />} 
        show={show} 
        handleClose={handleClose}
      />
      <LoadingModal handleClose={closeIsLoadingModal} show={isLoadingModal}/>
    </div>
  );
}

export default PatientList;


