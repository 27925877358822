import Modal from 'react-bootstrap/Modal';
import React, { useEffect } from 'react';
import { useState } from 'react';
import styles from './Modal.module.css'

const TosModal = ({ handleClose, handleConfirm, show }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [date, setDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const formattedDate = `${
      (today.getMonth() + 1).toString().padStart(2, '0') // Month is 0-based
    }/${today.getDate().toString().padStart(2, '0')}/${
      today.getFullYear()
    }`;
    setDate(formattedDate);
  },[]);

  const handleCheck = () => setIsChecked(!isChecked);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className={styles.floatingModalHeader}>
          <Modal.Title>Terms of Service & Policies</Modal.Title>
        </Modal.Header>
        <Modal.Body className={`${styles.floatingModalBody} ${styles.tosBody}`}>
          <h6>Privacy Policy</h6>
          Effective Date: {date} <br />
          At MySchedulePal, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your information.
          1. Information We Collect
          - Personal Identifiable Information (PII): Name, email address, phone number, etc.
          - Health Information (if applicable): Data related to scheduling medical appointments.
          - Usage Data: Device information, IP address, and usage patterns.
          2. How We Use the Information
          We use your information to:
          - Provide and improve the Service.
          - Ensure compliance with legal and regulatory standards.
          - Communicate updates or changes to the Service.
          3. Data Sharing
          We do not sell your information. We may share data with:
          - Service providers (e.g., cloud storage, payment processors).
          - Authorized entities as required by law.
          - We have agreements setup to protect Patient PHI, ensuring HIPAA Compliance.
          4. Data Security
          We employ robust security measures, including encryption and secure storage, to protect your data from unauthorized access. We also employ a Business Associate Agreement with our 3rd party api provider, ensuring privacy and HIPAA Compliance.
          5. User Rights
          Users have the right to:
          - Access and review their data.
          - Request corrections or deletions.
          - Opt out of non-essential data processing.
          6. HIPAA Compliance
          For users in the healthcare sector, we comply with HIPAA standards to protect PHI. Please avoid sharing sensitive health data through non-secure channels.
          If you have questions about this Privacy Policy, contact us at support@myschedulepal.com.
          <h6 className='mt-2'>Terms of Service</h6>
          Effective Date: {date} <br />
          Welcome to MySchedulePal! These Terms of Service ("Terms") govern your use of our services, including our mobile application and website (collectively, the "Service"). By using MySchedulePal, you agree to be bound by these Terms. Please read them carefully.
          1. User Responsibilities
          Users agree not to misuse the Service, including but not limited to:
          - Sharing accounts or login credentials with others.
          - Using the Service for unlawful purposes.
          - Interfering with the normal operation of the Service.
          - Sending, showing or using patient information in a way that would break HIPAA Compliance.
          2. Service Disclaimer
          MySchedulePal is designed to assist with scheduling and time management but is not a replacement for professional medical or healthcare advice. Always consult qualified professionals when making health-related decisions.
          3. Limitation of Liability
          To the maximum extent permitted by law, MySchedulePal is not liable for any damages arising from the use or inability to use the Service, including errors, interruptions, or unauthorized access.
          4. HIPAA Disclaimer
          If the Service handles Protected Health Information (PHI), it complies with HIPAA guidelines. However, users are responsible for not sharing PHI through unprotected channels.
          5. Governing Law
          These Terms are governed by the laws of Your Jurisdiction. Any disputes will be resolved in the courts of Your Jurisdiction.
          6. Termination Clause
          We reserve the right to suspend or terminate accounts for violations of these Terms or any other policies.
          If you have questions about these Terms, contact us at support@myschedulepal.com.

        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <div className={styles.checkboxContainer}>
            <input
              type="checkbox"
              id="custom-checkbox"
              checked={isChecked}
              onChange={handleCheck}
            />
            <label className={styles.tosAgree} htmlFor="custom-checkbox">I have read and agree to the Terms of Service and Privacy Policy.</label>
          </div>
          
          <button disabled={!isChecked} className='primaryBtn' onClick={handleConfirm}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TosModal;