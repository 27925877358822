import useEnviornmentRequests from './enviornment.js';

function useScheduleRequests () {
  const { getEnviornment } = useEnviornmentRequests();
  let url = getEnviornment();

  const returnObject = (status, message) => {
    return {
      status: status,
      message: message
    }
  };

  const saveEventToSchedule = async (userId, schedule, accessToken) => {
    try {
      const options = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}`},
        body: JSON.stringify(schedule)
      };

      const response = await fetch(`${url}/schedule/${userId}`, options);
      return returnObject(response.status, 'Success');

    } catch (error) {
        console.log(error);
        return returnObject(500, `${error.message}`);
    }
  };

  const updateEvent = async (updatedEvent, accessToken) => {
    try {
      const options = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}`},
        body: JSON.stringify(updatedEvent)
      };

      const response = await fetch(`${url}/event`, options);
      return returnObject(response.status, 'Success');

    } catch (error) {
        console.log(error);
        return returnObject(500, `${error.message}`);
    }
  };

  const getUserSchedule = async (userId, accessToken) => {
    try {
      const response = await fetch(`${url}/schedule/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      const scheduleData = await response.json();

      return scheduleData;

    } catch (error) {
        console.log(error);
        return returnObject(500, `${error.message}`);
    }
  };

  const deleteSchedule = async (userId, accessToken) => {
    try {
      const options = {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}`},
      };

      const response = await fetch(`${url}/schedule/all/${userId}`, options); 
      return returnObject(response.status, response.message);
    } catch (error) {
        console.log(error);
        return returnObject(500, `${error.message}`);
    }
  };

  const clearScheduleFromDay = async (accessToken, schedule) => {
    try {
      const options = {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}`},
        body: JSON.stringify(schedule)
      };

      const response = await fetch(`${url}/schedule/day`, options); 
      return returnObject(response.status, response.message);
    } catch (error) {
        console.log(error);
        return returnObject(500, `${error.message}`);
    }
  };

  const deleteEvent = async (userId, event, accessToken) => {
    try {
      const options = {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}`},
        body: JSON.stringify(event)
      };

      const response = await fetch(`${url}/schedule/patient/${userId}`, options); 
      if(response.status === 204) {
        return response;
      } else {
        return response.json();
      }
      
    } catch (error) {
        console.log(error);
        return returnObject(500, `${error.message}`);
    }
  };

  return {
    saveEventToSchedule,
    updateEvent,
    getUserSchedule,
    deleteSchedule,
    clearScheduleFromDay,
    deleteEvent
  }
}

export default useScheduleRequests;