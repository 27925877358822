import React, { useContext, useEffect, useState, useRef } from 'react';

import {UserContext, AccessTokenContext} from '../../context/context';
import usePatientRequests from '../../hooks/patient-requests';;
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPatients } from "../../App/Features/Patient_Scheduling/Patients_Thunks.js";
import { selectPatientId, toggleMapExpansion } from "../../App/Features/Patient_Scheduling/Patients_Slice.js";

import Loading from '../../pop-ups/loading';
import useDistanceRequests from '../../hooks/distance-request';
import CustomizeGroups from '../../Modal_Components/Customize_Groups';
import FloatingModal from '../../static/Modals/Floating_Modal';

import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  CircleMarker,
  useMap
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Container } from 'react-bootstrap';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import styles from './Grouping_Map.module.css';

const getTileLayerUrl = (mapStyle) => {
  switch (mapStyle) {
    case 'OpenStreetMap':
      return {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: '&copy; OpenStreetMap contributors',
      };
    case 'MapboxStreets':
      return {
        url: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`,
        attribution: '&copy; <a href="https://www.mapbox.com/">Mapbox</a>',
      };
    case 'MapboxSatellite':
      return {
        url: `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`,
        attribution: '&copy; <a href="https://www.mapbox.com/">Mapbox</a>',
      };
    case 'CartoLight':
      return {
        url: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
        attribution: '&copy; <a href="https://www.carto.com/">CARTO</a>',
      };
    case 'CartoDark':
      return {
        url: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
        attribution: '&copy; <a href="https://www.carto.com/">CARTO</a>',
      };
    default:
      // Fallback to OpenStreetMap
      return {
        url: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`,
        attribution: '&copy; <a href="https://www.mapbox.com/">Mapbox</a>',
      };
  }
};

const GroupingMap = ({ mapStyle = 'MapboxStreets' }) => {
  const user = useContext(UserContext);
  const accessToken = useContext(AccessTokenContext);
  const { getPatients } = usePatientRequests();
  const { groupPatients } = useDistanceRequests();
  const { data: patients, refetch } = useQuery('patients', 
    () => getPatients(user.authId, accessToken)
  );
  const dispatch = useDispatch();
  const { selectedPatientId, isMapExpanded } = useSelector((state) => state.patients);

  const [isLoading, setIsLoading] = useState(true);
  const [showCustomizeGroups, setShowCustomizegroups] = useState(false);
  const [patientRadius, setPatientRadius] = useState(2);
  const [leafletMap, setLeafletMap] = useState(null);

	const markerRefs = useRef({});

  const customHomeIcon = L.icon({
    iconUrl: '/assets/icons/Home.svg',
    iconSize: [20,20], 
    iconAnchor: [12, 41],
    popupAnchor: [0, -41], 
  });

  const clusterMap = {
    0: '#6a0dad',  // Dark Purple
    1: '#c2185b',  // Deep Pink
    2: '#0077b6',  // Rich Blue
    3: '#d97706',  // Golden Orange
    4: '#1b5e20',  // Dark Green
    5: '#b71c1c',  // Deep Crimson
    6: '#0c5460',  // Teal Blue
    7: '#4e342e',  // Dark Brown
  };

  const radiusMap = {
    1: 6,
    2: 12,
    3: 18
  };

  const MapInitializer = () => {
    const map = useMap();

    useEffect(() => {
      setLeafletMap(map); // Pass the map instance to the parent state
    }, [map]);

    return null; // Doesn't render anything visually
  };
  
  const tileLayerConfig = getTileLayerUrl(mapStyle);

  const showMarker = () => {
    if (leafletMap && selectedPatientId !== '') {
      const selectedPatientArr = patients.message.filter((patient) => patient._id === selectedPatientId);
      const deIdentifiedCoordinates = selectedPatientArr[0].deIdentifiedCoordinates;
      leafletMap.flyTo([deIdentifiedCoordinates.latitude, deIdentifiedCoordinates.longitude], 11);
      setTimeout(() => {
        const marker = markerRefs.current[selectedPatientId];
        if (marker) {
          marker.openPopup()
        }
      }, 400);
    }
	}

  useEffect(() => {
    showMarker();
  }, [selectedPatientId]);
  

  useEffect(() => {
    if(patients !== undefined) {
      const storedCustomization = JSON.parse(localStorage.getItem('groupCustomization'));
      if(!storedCustomization) {
        localStorage.setItem(
          'groupCustomization', 
          JSON.stringify({clusters: 5, size: 1})
        );
      } else {
        setPatientRadius(storedCustomization.size);
      }

      const isMissingGroup = patients.message.some((patient) => !('group' in patient));

      if(isMissingGroup) {
        groupPatientsRequest();
      } else {
        setIsLoading(false);
      }
    }
  }, [patients]);

  const handleShowCustomizegroups = () => {
    setShowCustomizegroups(!showCustomizeGroups);
  }

  const groupPatientsRequest = async () => {
    try {
      const storedCustomization = JSON.parse(localStorage.getItem('groupCustomization'));
      setPatientRadius(storedCustomization.size);
      await groupPatients(user.authId, accessToken, storedCustomization.clusters);
      await refetch();
      dispatch(fetchPatients({ user, accessToken }));
    } catch (error) {
      console.error('Error:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickMarker = (patientId) => {
    dispatch(selectPatientId(patientId));
  }

  const handleExpand = () => {
    setIsLoading(true);
    dispatch(toggleMapExpansion());
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }
  

  if(patients === undefined || isLoading) {
    return (
      <Container className='d-flex justify-content-center align-items-center'>
        <Loading />
      </Container>
    );
  }

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <div style={{ position: 'absolute', top: '10px', right: '100px', zIndex: 500 }}>
        <button
          onClick={handleShowCustomizegroups}
          className='primaryBtnSmall'
          style={{boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.5)"}}
        >
          Customize
        </button>
      </div>
      <div style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 500 }}>
        <button
          onClick={handleExpand}
          className='secondaryBtnSmall'
          style={{boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.5)"}}
        >
          {isMapExpanded ? 'Collapse' : 'Expand'}
        </button>
      </div>

    {/* Map */}
      <MapContainer
        className={styles.generalMap}
        center={[user.coordinates.latitude, user.coordinates.longitude]}
        zoom={9}
        scrollWheelZoom={true}
      >
        <MapInitializer />

        <TileLayer url={tileLayerConfig.url} attribution={tileLayerConfig.attribution} />
        {patients.message.map((patient, index) => (
          <CircleMarker
            key={index}
            ref={(el) => { 
              if (el) {
                markerRefs.current[patient._id] = el;
              }
            }}
            center={[
              patient.deIdentifiedCoordinates.latitude,
              patient.deIdentifiedCoordinates.longitude,
            ]}
            pathOptions={{
              color: clusterMap[patient.group],
              fillColor: clusterMap[patient.group],
              fillOpacity: 0.75,
            }}
            radius={radiusMap[patientRadius]}
            eventHandlers={{
              click: () => handleClickMarker(patient._id)
            }}
          >
            <Popup>{patient.firstName} {patient.lastName}</Popup>
          </CircleMarker>
        ))}
        <Marker position={[user.coordinates.latitude, user.coordinates.longitude]} icon={customHomeIcon}>
          <Popup>Me</Popup>
        </Marker>
      </MapContainer>
      <FloatingModal 
        title="Customize" 
        component={<CustomizeGroups handleClose={handleShowCustomizegroups} handleSave={groupPatientsRequest} />} 
        show={showCustomizeGroups} 
        handleClose={handleShowCustomizegroups}
      />
    </div>
    
  );
}

export default GroupingMap;