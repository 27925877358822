import Modal from 'react-bootstrap/Modal';

import styles from './Modal.module.css';

const LoadingModal = ({ handleClose, show }) => {
  return (
    <Modal
      className={styles.transparentModal}
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className={styles.transparentModal}>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <div className="d-flex justify-content-center align-items-center">
            <div
              className="spinner-grow m-1"
              style={{ color: "var(--primary)" }}
              role="status"
            ></div>
            <div
              className="spinner-grow m-1"
              style={{ color: "var(--secondary)" }}
              role="status"
            ></div>
            <div
              className="spinner-grow m-1"
              style={{ color: "var(--primary)" }}
              role="status"
            ></div>
            <div
              className="spinner-grow m-1"
              style={{ color: "var(--secondary)" }}
              role="status"
            ></div>
            <div
              className="spinner-grow m-1"
              style={{ color: "var(--primary)" }}
              role="status"
            ></div>
            <div
              className="spinner-grow m-1"
              style={{ color: "var(--secondary)" }}
              role="status"
            ></div>
          </div>
          <div className={styles.loadingTitle}>Gathering Your Data</div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LoadingModal;
