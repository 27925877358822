import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import FAQs from '../../Modal_Components/Faq';
import FloatingModal from '../Modals/Floating_Modal';

import styles from './Nav.module.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const IntroNav = ({ location }) => {
  const { loginWithRedirect } = useAuth0();
  const [activeLink, setActiveLink] = useState("#home");
  const [showFaqs, setShowFaqs] = useState(false);

  const handleSelect = (eventKey) => {
    setActiveLink(eventKey);
  };

  const handleSignup = () => {
    loginWithRedirect({
      authorizationParams: { screen_hint: 'signup',},
    })
  };

  const handleCloseFaqs = () => setShowFaqs(false);

  const joinButton = () => {
    return <button className="secondaryBtn" onClick={() => handleSignup()}>Sign Up</button>
  }

  return (
    <Navbar collapseOnSelect expand="lg" className={styles.navbar}>
      <Container>
        <Navbar.Brand className={styles.custNavbarBrand} href="#home">MySchedulePal</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto" activeKey={activeLink} onSelect={handleSelect}>
            <Nav.Link 
              href="#features" 
              eventKey="#features" 
              className={`${styles.navLink} ${activeLink === "#features" ? styles.navLinkActive : ""}`}
            >
              Features
            </Nav.Link>
            <Nav.Link 
              href="#steps" 
              eventKey="#steps" 
              className={`${styles.navLink} ${activeLink === "#steps" ? styles.navLinkActive : ""}`}
            >
              How it Works
            </Nav.Link>
            <Nav.Link 
              href="#hippa" 
              eventKey="#hippa" 
              className={`${styles.navLink} ${activeLink === "#hippa" ? styles.navLinkActive : ""}`}
            >
              HIPPA
            </Nav.Link>
            <Nav.Link 
              href="#pricing" 
              eventKey="#pricing" 
              className={`${styles.navLink} ${activeLink === "#pricing" ? styles.navLinkActive : ""}`}
            >
              Pricing
            </Nav.Link>
            <Nav.Link 
              href="#about" 
              eventKey="#about" 
              className={`${styles.navLink} ${activeLink === "#about" ? styles.navLinkActive : ""}`}
            >
              About Us
            </Nav.Link>  
            <Nav.Link 
              onClick={() => setShowFaqs(true)}
              eventKey="#faq" 
              className={`${styles.navLink} ${activeLink === "#faq" ? styles.navLinkActive : ""}`}
            >
              FAQs
            </Nav.Link>           
          </Nav>
          <Nav activeKey={activeLink} onSelect={handleSelect}>
            <Nav.Link 
              onClick={() => loginWithRedirect()}
              href="#login" 
              eventKey="#login" 
              className={`${styles.navLink} ${activeLink === "#login" ? styles.navLinkActive : ""} d-flex align-items-center`}
            >
              Login
            </Nav.Link>  
            {joinButton()}
          </Nav>
        </Navbar.Collapse>
      </Container>
      <FloatingModal 
        title="FAQs" 
        component={<FAQs />} 
        show={showFaqs} 
        handleClose={handleCloseFaqs}
      />
    </Navbar>
  );
}

export default IntroNav;

