import { useEffect, useState, useContext, useRef } from "react";

import useUserRequests from "../hooks/user-requests";
import { UserContext, AccessTokenContext } from '../context/context';
import { useAlert } from "../context/alert-context";
import { LocationClient, SearchPlaceIndexForSuggestionsCommand } from '@aws-sdk/client-location';

import "react-datepicker/dist/react-datepicker.css";
import styles from "./Modal_Components.module.css"
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const SchedulePreferences = ({handleClose}) => {
  const access_Key_Id = process.env.REACT_APP_AWS_ACCESS_KEY_ID ;
  const secret_Access_Key = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
  const { updateUser } = useUserRequests();
  const user = useContext(UserContext);
  const accessToken = useContext(AccessTokenContext);
  const { showAlert } = useAlert();
  const suggestionsRef = useRef(null);

  const [suggestions, setSuggestions] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [parameters, setParameters] = useState({
    workingDays: user.workingDays,
    sessionLength: user.sessionLength,
    bufferTime: user.bufferTime,
    startTime: user.startTime,
    address: user.address,
  });

  const closeSuggestions = () => setSuggestions([]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
        closeSuggestions();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  const client = new LocationClient({
    region: 'us-east-1',
    credentials: {
      accessKeyId: access_Key_Id,
      secretAccessKey:secret_Access_Key
    }
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
            setUserLocation([position.coords.longitude, position.coords.latitude]);
        },
        (error) => {
            console.error("Error getting user location:", error);
        }
      );
    } else {
      console.log("user location unseen.")
    }
  }, []);
  
  const fetchSuggestions = async (input) => {
    const command = new SearchPlaceIndexForSuggestionsCommand({
        IndexName: 'MySchedulePal-PI',
        Text: input,
        MaxResults: 5,
        BiasPosition: userLocation
    });

    try {
      const response = await client.send(command);
      const suggestionTexts = response.Results.map(suggestion => suggestion.Text);
      setSuggestions(suggestionTexts);
    } catch (error) {
        console.error('Error fetching suggestions:', error);
    }
  }

  const handleAddressChange = (e) => {
    const value = e.target.value;
    setParameters(prev => ({
      ...prev,
      address: value
    }));

    if (value) {
      fetchSuggestions(value);
    } else {
      setSuggestions([]);
    }
  }

  const handleSelectAddress = (addressSelected) => {
    setParameters(prev => ({
      ...prev,
      address: addressSelected
    }));

    setSuggestions([]);
  }

  const handleParameterChange = (e) => {
    const { name, value } = e.target;

    setParameters(prev => ({
        ...prev,
        [name]: value
    }));
  }

  const handleSaveParameters = async () => {
    // handleTherapistParameters(parameters);
    const response = await updateUser(user.authId, accessToken, parameters);
    if(response.status === 200) {
      handleClose();
      showAlert(
        "You're workday has been updated. Changes will be reflected in new patients scheduled.", 
        "Success", 'success'
      );
    } else {
      showAlert(
        `We're unable to update your wokday parameters. ${response.message} Refresh and try again.`, 
        "Oh, Snap", 'danger'
      );
    }  
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Account for unexpected delays: traffic, hard to find addresses or just a second to breathe and prepare.
    </Tooltip>
  );

  return (
    <div className="p-1">
      <div className={`${styles.doubleInputContainer} mb-3`}>
        <div className="d-flex align-items-center justify-content-start flex-column mb-lg-3" style={{'position': 'relative'}}>
          <label htmlFor="first" className="mspLabel address d-flex justify-content-start align-items-start">Starting Location (Your Home Address)</label>
          <input
            className="mspInput address mb-2"
            type="text"
            value={parameters.address}
            onChange={handleAddressChange}
            placeholder="Start typing address..."
          />
          <div ref={suggestionsRef} className={`${suggestions.length !== 0 ? `${styles.suggestionsContainer} ${styles.suggestionsContainerPref}` : "d-none"}`}>
            <ul>
              {suggestions.map((suggestion, index) => (
                  <li className={styles.addressItem} key={index} onClick={() => handleSelectAddress(suggestion)}>{suggestion}</li>
              ))}
            </ul>
          </div>       
        </div>
      </div>
      <div className={`${styles.doubleInputContainer} mb-3`}>
        <div className="d-flex m-2 align-items-center justify-content-start flex-column">
          <label htmlFor="first" className="mspLabel small d-flex justify-content-start align-items-start">Typical Start Time</label>
          <input className="mspInput small" type="time" name="startTime" value={parameters.startTime} onChange={handleParameterChange} />
        </div>
        <div className="d-flex m-2 align-items-center justify-content-start flex-column">
          <label htmlFor="first" className="mspLabel small d-flex justify-content-start align-items-start">Session Length</label>
          <div className={styles.inputWrapper}>
            <input type="number" className={styles.inputFlex} name="sessionLength" value={parameters.sessionLength} onChange={handleParameterChange} placeholder="50" />
            <span className={`${styles.labelFlex} px-2`}>min</span>
          </div>
        </div>
        <div className="d-flex m-2 align-items-center justify-content-start flex-column">
          <label htmlFor="first" className="mspLabel small d-flex justify-content-start align-items-start">
            Buffer Time
            <span className="d-flex align-items-center justify-content-center">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <img src="assets/icons/Info.svg" alt="info" style={{ width: '15px', height: '15px' }} />
              </OverlayTrigger>
            </span>
          </label>
          <div className={styles.inputWrapper}>
            <input type="number" className={styles.inputFlex} name="bufferTime" value={parameters.bufferTime} onChange={handleParameterChange} placeholder="5" />
            <span className={`${styles.labelFlex} px-2`}>min</span>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-end pt-4">
        <button className="primaryBtn" disabled={!parameters.address || !parameters.startTime || !parameters.sessionLength || !parameters.bufferTime} onClick={handleSaveParameters}>Save</button>
      </div>
    </div>
  );
}

export default SchedulePreferences;

    