import React from "react";

const Loading = () => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh"}}>
      <div className="spinner-grow m-1" style={{ color: "var(--primary)" }} role="status">
      </div>
      <div className="spinner-grow m-1" style={{ color: "var(--secondary)" }} role="status">
      </div>
      <div className="spinner-grow m-1" style={{ color: "var(--primary)" }} role="status">
      </div>
      <div className="spinner-grow m-1" style={{ color: "var(--secondary)" }} role="status">
      </div>
      <div className="spinner-grow m-1" style={{ color: "var(--primary)" }} role="status">
      </div>
      <div className="spinner-grow m-1" style={{ color: "var(--secondary)" }} role="status">
      </div>
    </div>
  )
};

export default Loading;