import React from 'react';
import { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

import FloatingModal from '../Modals/Floating_Modal';
import FAQs from '../../Modal_Components/Faq';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import styles from './Nav.module.css'; 

const AppNavbar = () => {
  const location = useLocation();
  const [showFaqs, setShowFaqs] = useState(false);

  if (location.pathname === '/register') {
    return null;
  }

  const handleCloseFaqs = () => setShowFaqs(false);

  return (
    <Navbar collapseOnSelect expand="lg" className={`${styles.navbar} px-2`}>
      <Navbar.Brand className={styles.custNavbarBrand} as={Link} to="/">
        MySchedulePal
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link 
            as={Link} 
            to="/" 
            className={`${styles.navLink} ${location.pathname === "/" ? styles.navLinkActive : ""}`}
          >
            Dashboard
          </Nav.Link>
          <Nav.Link 
            as={Link} 
            to="/patients" 
            className={`${styles.navLink} ${location.pathname === "/patients" ? styles.navLinkActive : ""}`}
          >
            Patients
          </Nav.Link>
          <Nav.Link 
            as={Link} 
            to="/schedule" 
            className={`${styles.navLink} ${location.pathname === "/schedule" ? styles.navLinkActive : ""}`}
          >
            Scheduling
          </Nav.Link>
          <Nav.Link 
            onClick={() => setShowFaqs(true)}
            eventKey="#faq" 
            className={`${styles.navLink}`}
          >
            FAQs
          </Nav.Link> 
        </Nav>
        <Nav>
          <Nav.Link 
            as={Link} 
            to="/profile" 
            className={`${styles.navLink} ${location.pathname === "/profile" ? styles.navLinkActive : ""}`}
          >
            Profile
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <FloatingModal 
        title="FAQs" 
        component={<FAQs />} 
        show={showFaqs} 
        handleClose={handleCloseFaqs}
      />
    </Navbar>
  );
};

export default AppNavbar;
