import Modal from 'react-bootstrap/Modal';
import styles from './Modal.module.css'

const ModalComponent = ({ title, component, show, handleClose, handleAction, actionTitle, buttonClass }) => {

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className={styles.floatingModalHeader} closeButton>
          <Modal.Title id={styles.title}>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{component}</Modal.Body>
        <Modal.Footer>
          <button className={`${buttonClass}`} onClick={handleAction}>
            {actionTitle}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalComponent;