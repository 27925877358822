import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { UserContext, AccessTokenContext } from '../../context/context.js';
import { fetchPatients } from "../../App/Features/Patient_Scheduling/Patients_Thunks.js";
import { selectPatientId, toggleGrouping } from '../../App/Features/Patient_Scheduling/Patients_Slice.js';

import Loading from "../../pop-ups/loading";
import FloatingModal from "../../static/Modals/Floating_Modal";
import CreatePatient from "../../Modal_Components/Create_Patient";

import styles from "./Patient_Schedule_List.module.css";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const PatientScheduleList = ({ handleDragStart, myEvents, start, end }) => {
  const user = useContext(UserContext);
  const accessToken = useContext(AccessTokenContext);
  const dispatch = useDispatch();
  const { list, status, isGroupingOn, selectedPatientId } = useSelector((state) => state.patients);

  // const [remainingPatients, setRemainingPatients]=useState([]);
  const [activePopoverId, setActivePopoverId] = useState(null);
  const [showNewPatientModal, setShowNewPatientModal] = useState(false);

  const clusterMap = {
    0: '#6a0dad',  // Dark Purple
    1: '#c2185b',  // Deep Pink
    2: '#0077b6',  // Rich Blue
    3: '#d97706',  // Golden Orange
    4: '#1b5e20',  // Dark Green
    5: '#b71c1c',  // Deep Crimson
    6: '#0c5460',  // Teal Blue
    7: '#4e342e',  // Dark Brown
  };

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchPatients({ user, accessToken }));
    }
  }, [status, user.authId, accessToken, dispatch]);

  useEffect(() => {
    if(selectedPatientId !== '' && selectedPatientId !== null) {
      setActivePopoverId(selectedPatientId);
    }
  }, [selectedPatientId])

  const groupObject = React.useMemo(() => {
    if (!list) return {};
    return list.reduce((acc, patient) => {
      if (!acc[patient.group]) {
        acc[patient.group] = [];
      }
      acc[patient.group].push(patient);
      return acc;
    }, {});
  }, [list]);
  
 
  // useEffect(() => {
  //   if(homes && start) {
  //     const viewStart = new Date(start.setHours(0, 0, 0, 0)).getTime();
  //     const viewEnd = new Date(end.setHours(23, 59, 59, 999)).getTime();

  //     const activePatients = homes.message.filter((home) => home.active && home.address !== '');
      
  //     const eventsUsed = myEvents.filter((event) => {
  //       const eventStart = new Date(event.start).getTime();
  //       return eventStart >= viewStart && eventStart <= viewEnd;
  //     });
  
  //     const patients = activePatients.map((patient) => {

  //       const patAddress = abbrevationFix(patient.address);
  //       const frequency = parseInt(patient.frequency);
        
  //       const patientsEvents =  eventsUsed.filter((event) => {
  //         const evAddress = abbrevationFix(event.address);

  //         return evAddress === patAddress && event.title === `${patient.firstName} ${patient.lastName}`
  //       });
      
  //       if(frequency > patientsEvents.length) {
  //         patient.additional = false;
  //         return patient;
  //       };
        
  //       if(frequency <= patientsEvents.length){
  //         patient.additional = true;
  //         return patient;
  //       };
  //     });

      // patients.sort((a, b) => a.firstName.localeCompare(b.firstName));
  
      // setRemainingPatients(patients);

      // // const sortedGroups = patients.reduce((acc, patient) => {
      // //   if (!acc[patient.group]) {
      // //     acc[patient.group] = [];
      // //   }
      // //   acc[patient.group].push(patient);
      // //   return acc;
      // // }, {});

      // // setGroupObject(sortedGroups)
  //   }
  // },[homes, myEvents, start]);

  const handleTogglePopover = (patientId) => {
    setActivePopoverId((prevId) => (prevId === patientId ? null : patientId));
    dispatch(selectPatientId(patientId));
  };

  const handleCloseModal = async () => {
    dispatch(fetchPatients({ user, accessToken }));
    setShowNewPatientModal(false);
  }

  const handleAddPatient = () => setShowNewPatientModal(true);

  const handleGroupingToggle = () => {
    dispatch(toggleGrouping());
  }
  
  const popover = (patient) => (
    <Popover id={`${styles.popoverBasic}`}>
      <Popover.Header className={styles.popoverHeader} as="h3">
        {patient.firstName} {patient.lastName}
      </Popover.Header>
      <Popover.Body>
        <div>
          <div className={`${patient.primaryNumber === "" ? "d-none" : "d-flex"} d-flex flex-column mb-2`}>
            <div className={`${styles.label} d-flex`}>Primary:</div>
            <div className={`${styles.infoDiv} d-flex flex-wrap`}>
              {patient.primaryNumber || "--"}
            </div>
          </div>
          <div className="d-flex flex-column mb-2">
            <div className={`${styles.label} d-flex`}>Address:</div>
            <div className={`${styles.infoDiv} d-flex flex-wrap`}>
              {patient.address}
            </div>
          </div>
          <div className="d-flex flex-column mb-2">
            <div className={`${styles.label} d-flex`}>Frequency:</div>
            <div className={`${styles.infoDiv} d-flex flex-wrap`}>
              {patient.frequency}/Week
            </div>
          </div>
          <div className={`${Object.values(patient.noSeeDays).some(value => value === true) ? "d-flex" : "d-none"} d-flex flex-column mb-2`}>
            <div className={`${styles.label} d-flex`}>Cannot Be Seen:</div>
            <div className="d-flex flex-wrap align-items-center">
              <div className="d-flex flex-wrap">
                {patient.noSeeDays &&
                  Object.entries(patient.noSeeDays).map(
                    ([propertyName, propertyValue]) =>
                      propertyValue ? (
                        <div
                          className="d-flex me-1 mb-1 badge"
                          id="customBadge"
                          key={propertyName}
                        >
                          {propertyName}
                        </div>
                      ) : null
                  )}
              </div>
            </div>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  const header = () => {
    return (
      <div className="d-flex align-items-start mb-2 justify-content-between">
        <button onClick={handleAddPatient} className="transparentBtnSecondary">Add Patient</button>
        <button onClick={handleGroupingToggle} className="transparentBtnSecondary">{isGroupingOn ? 'Normal View' : 'Group Patients'}</button>
      </div>
    );
  }

  if (status === 'loading') return <div><Loading /></div>;

  if (status === 'failed') {
    return (
      <div className="d-flex flex-column justify-content-center align-items center">
        <div className="d-flex justify-content-center align-items center"><img src="/assets/images/Scheduling.png" alt="Notes" width={200} height={200} /></div>
        <div className={`${styles.noPatientDiv}`}>Add a few patients to get started. From there it's easy; Drag, Drop & Test!</div>
        <button onClick={handleAddPatient} className="transparentBtnSecondary my-2">Add Patient</button>
        <FloatingModal 
          title="New Patient" 
          component={<CreatePatient userId={user.authId} accessToken={accessToken} handleClose={handleCloseModal}  />} 
          show={showNewPatientModal} 
          handleClose={handleCloseModal}
        />
      </div>
    );
  }

  if(isGroupingOn) {

    return (
      <div className="d-flex flex-column justify-content-start align-items-start">
        {header()}
        <div className="d-flex justify-content-start align-items-start w-100 flex-wrap">
          {
            Object.entries(groupObject).map(([group, patients]) => (
              <div className="d-flex flex-wrap my-1" style={{ border: `2px solid ${clusterMap[group]}`, borderRadius: '12px' }} key={group}>
                {patients.map((patient) => (
                  <div key={patient._id} className="d-flex justify-content-center align-items-center m-1">
                    <OverlayTrigger trigger="click"
                      placement="top"
                      show={activePopoverId === patient._id}
                      onToggle={() => handleTogglePopover(patient._id)}
                      overlay={popover(patient)}
                    >
                      <button 
                        draggable 
                        onDragStart={() => handleDragStart(`${patient.firstName} ${patient.lastName}`, patient.address, patient.coordinates, patient.additional ? null : undefined, patient.additional, null)} 
                        className={`${styles.patientCard} d-flex justify-content-center align-items-center`}
                        style={{ background: `${clusterMap[group]}`, border: `1px solid ${clusterMap[group]}` }}
                      >
                        {patient.firstName} {patient.lastName}
                      </button>
                    </OverlayTrigger>
                  </div>
                  ))}
              </div>
            ))
          }
        </div>
        <FloatingModal 
          title="New Patient" 
          component={<CreatePatient userId={user.authId} accessToken={accessToken} handleClose={handleCloseModal}  />} 
          show={showNewPatientModal} 
          handleClose={handleCloseModal}
        />
      </div>
    );
  }

 
  return (
    <div className="d-flex flex-column justify-content-start align-items-start">
     {header()}
      <div className="d-flex justify-content-start align-items-start w-100 flex-wrap">
          {list.map((patient) => 
            (   
              <div key={patient._id} className="d-flex justify-content-center align-items-center m-1">
                <OverlayTrigger trigger="click"
                  placement="top"
                  show={activePopoverId === patient._id}
                  onToggle={() => handleTogglePopover(patient._id)}
                  overlay={popover(patient)}
                >
                  <button 
                    draggable 
                    onDragStart={() => handleDragStart(`${patient.firstName} ${patient.lastName}`, patient.address, patient.coordinates, patient.additional ? null : undefined, patient.additional, null)} 
                    className={`${styles.patientCard} d-flex justify-content-center align-items-center`}
                  >
                    {patient.firstName} {patient.lastName}
                  </button>
                </OverlayTrigger>
              </div>
            ) 
          )}
      </div>
      <FloatingModal 
        title="New Patient" 
        component={<CreatePatient userId={user.authId} accessToken={accessToken} handleClose={handleCloseModal}  />} 
        show={showNewPatientModal} 
        handleClose={handleCloseModal}
      />
    </div>    
  );
}

export default PatientScheduleList

  