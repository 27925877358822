import React from 'react';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import Home from './static/Home/Home.js'
import Loading from './pop-ups/loading.js';

import AppNavbar from './static/Nav/App_Navbar.js'
import PatientList from './Features/Patient_List/Patient_List.js';
import Dashboard from './Features/Dashboard/dashboard.js';
import Calendar from './Features/Calendar/Calendar.js';
import LogoutButton from './auth/LogoutButton.js';
import Profile from './auth/Profile/Profile.js';
import Onboarding from './components/Onboarding/Onboarding.js'
import PatientInfo from './Features/Patient_Info/Patient_Info.js';
import ModalWrapper from './components/Modal-Wrapper.js';
import ErrorScreen from './static/Error/Error_Screen.js';

import { UserContext, AccessTokenContext } from "./context/context.js"
import { useAlert } from './context/alert-context.js';
import useUserRequests from './hooks/user-requests.js';
import useModalInfo from './hooks/modal-info.js';

import './App.css'
import 'bootstrap/dist/css/bootstrap.css';
import Alert from 'react-bootstrap/Alert';

function App() {
  const { getUser } = useUserRequests();
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, error } = useAuth0();
  const { isOpen, modalType, modalProps, openModal, closeModal } = useModalInfo();
  const [validUser, setValidUser] = useState(false); 
  const [isError, setIsError] = useState({ error: false, message: '' });
  const [loading, setLoading] = useState(true);  
  const [userInfo, setUserInfo] = useState('');
  const [accessToken, setAccessToken] = useState('');

  const handleIsError = (boolean, errorMessage) => {
    setIsError(prevState => ({
      ...prevState,
      error: boolean,
      message: errorMessage
    }));
    setLoading(false);
  };

  const keepLoading = () => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const getAuthParams = () => {
    if(process.env.REACT_APP_CURRENT_ENV === 'local') {
      return {
        audience: "https://myschedulepal-local-api.com",
        scope: "openid profile email read:user write:user"
      }
    } else {
      return {
        audience: "https://myschedulepal-api.com",
        scope: "openid profile email read:user write:user"
      }
    }
  }

  useEffect(() => {
    let timeoutId;

    const retrieveToken = async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: getAuthParams()
      });
      setAccessToken(accessToken);
      return accessToken;
    };

    const isUserVerified = async () => {
      if(isAuthenticated && user && !validUser) {
        try {
          const token = await retrieveToken();
          const response = await getUser(user.sub, token);

          if(response.status === 404) {
            setValidUser(false);
            timeoutId = keepLoading();

          } else if(response.status !== 200) {
            timeoutId = keepLoading();
            handleIsError(true, response.message);
            setLoading(false);
          } else {
            setUserInfo(response.message);
            setValidUser(true);
            timeoutId = keepLoading();
          }
         
        } catch (error) {
            setIsError(true, error.message);
            setLoading(false);
            timeoutId = keepLoading();
        }
      }
    }
    
    isUserVerified();

    return () => {
      if (timeoutId) clearTimeout(timeoutId); // Clear the timeout if set
    };
  }, [isAuthenticated, user, getAccessTokenSilently]);

  const GlobalAlert = () => {
    const { alert, hideAlert } = useAlert();
  
    if (!alert.show) return null;
  
    return (
      <Alert variant={alert.variant} onClose={hideAlert} dismissible>
        <Alert.Heading>{alert.header}</Alert.Heading>
        <p>
          {alert.message}
        </p>
      </Alert>
    );
  }

  if (isError.error) {
    return <ErrorScreen errorMessage={isError.message} />;
  }

  if (isLoading) {
    return <Loading />;
  }

  
  if (!isAuthenticated) {
    return <Home />; 
  }

  if (loading) {
    return <Loading />;
  }

  if (isError.error) {
    return <ErrorScreen errorMessage={isError.message} />;
  }

  if(!validUser) {
    return <Onboarding authId={user.sub} email={user.email} accessToken={accessToken} />;
  }
  

  return (
    <div className='body'>
      <div className="global-alert">
        <GlobalAlert />
      </div>
      <div className={`overall ${isOpen ? 'modal-open' : ''}`}>
        <div>
          <Router>  
          <AppNavbar />
            <UserContext.Provider value={userInfo}>
              <AccessTokenContext.Provider value={accessToken}>
                <Routes> 
                  <Route exact path="/" element={<Dashboard/>} />
                  <Route exact path="/profile" element={<Profile/>} />
                  <Route exact path="/logout" element={<LogoutButton/>} />
                  <Route exact path="/patients" element={<PatientList openModal={openModal} isOpen={isOpen}/>} />
                  <Route path="/patients/:id" element={<PatientInfo isOpen={isOpen}/>} />
                  <Route exact path="/schedule" element={<Calendar openModal={openModal} isOpen={isOpen}/>} />
                </Routes>
              </AccessTokenContext.Provider>
            </UserContext.Provider>
          </Router>
        </div>
        {isOpen ? <ModalWrapper className="modal-wrapper" userId={userInfo.authId} accessToken={accessToken} modalType={modalType} modalProps={modalProps} closeModal={closeModal} /> : null}
      </div>
    </div>
  );
};

export default App;
